import { useState } from "react";
import { format, eachDayOfInterval, startOfWeek, endOfWeek, isSameDay } from "date-fns";
import { Doc } from "../../convex/_generated/dataModel";

interface HabitCalendarProps {
  completions: Doc<"completions">[];
}

const HabitWeeklyCalendar: React.FC<HabitCalendarProps> = ({ completions }) => {
  const [currentDate] = useState(new Date());
  const startDate = startOfWeek(currentDate, { weekStartsOn: 0 });
  const endDate = endOfWeek(currentDate, { weekStartsOn: 0 });
  const weekDays = eachDayOfInterval({ start: startDate, end: endDate });

  const completedDates = completions.map((completion) => format(completion.date, "yyyy-MM-dd"));

  return (
    <div className="flex space-x-1">
      {weekDays.map((date) => {
        const dateString = format(date, "yyyy-MM-dd");
        const dayLetter = format(date, "EEEEE");
        const isCompleted = completedDates.includes(dateString);
        const isToday = isSameDay(date, currentDate);

        return (
          <div key={dateString} className="flex flex-col items-center">
            <div
              className={`w-[1.5rem] h-[1.5rem] rounded-[0.6rem] flex items-center justify-center text-xs leading-relaxed font-light
                ${isCompleted ? "bg-primary-400 text-white" : isToday ? "border-2 border-primary-400 text-primary-400" : "border border-stone-200 text-stone-400"}
              `}
              title={format(date, "EEEE, MMMM d, yyyy")}
            >
              {dayLetter}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HabitWeeklyCalendar;
