import { api } from "../../convex/_generated/api";
import { useQuery, useMutation } from "convex/react";
import { useState } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { formatDistanceToNow } from "date-fns";
import Avatar from "react-avatar";
import { Id } from "../../convex/_generated/dataModel";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { MessageCircle, Heart, Trash2 } from "lucide-react";

type Comment = {
  profileName: string | undefined;
  _id: Id<"comments">;
  _creationTime: number;
  userId: string;
  completionId: Id<"completions">;
  createdAt: number;
  content: string;
};

export const LikesAndComments = ({ completionId }: { completionId: Id<"completions"> }) => {
  const deleteComment = useMutation(api.groups.deleteComment);

  const [newComment, setNewComment] = useState("");
  const [showAllComments, setShowAllComments] = useState(false);
  const [showLikes, setShowLikes] = useState(false);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const likesAndComments = useQuery(api.groups.getCompletionLikesAndComments, { completionId });
  const addComment = useMutation(api.groups.addComment);
  const likeCompletion = useMutation(api.groups.likeCompletion);
  const currentUser = useQuery(api.profiles.get);

  const handleAddComment = () => {
    if (newComment.trim()) {
      addComment({ completionId, content: newComment.trim() });
      setNewComment("");
    }
  };

  const handleDeleteComment = (commentId: Id<"comments">) => {
    deleteComment({ commentId });
  };

  const handleLike = () => {
    likeCompletion({ completionId });
  };

  if (!likesAndComments || !currentUser) return null;

  const { likes, comments } = likesAndComments;
  const hasLiked = likes.some((like) => like.userId === currentUser.userId);

  const renderComment = (comment: Comment, index: number) => (
    <div key={index} className="flex items-start space-x-2 mt-2 mb-3">
      <Avatar name={comment.profileName} size="22" round textSizeRatio={2} />
      <div className="flex-grow">
        <div className="text-xs text-muted-foreground">{comment.profileName}</div>
        <div className="text-sm font-regular">{comment.content}</div>
        <div className="text-xs text-muted-foreground mt-1">{formatDistanceToNow(comment.createdAt)} ago</div>
      </div>
      {comment.userId === currentUser.userId && (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => handleDeleteComment(comment._id)}
          className="text-red-500 hover:text-red-700"
        >
          <Trash2 size={14} />
        </Button>
      )}
    </div>
  );

  return (
    <div className="mt-3">
      <div className="flex items-center justify-between -mx-2">
        <div className="flex items-center">
          <Button
            variant="ghost"
            size="sm"
            onClick={handleLike}
            className={`text-sm ${hasLiked ? "text-red-600 focus:text-red-600 hover:text-red-600" : "text-stone-600 focus:text-stone-600 hover:text-stone-600"}`}
          >
            <Heart size={16} className="mr-2" fill={hasLiked ? "currentColor" : "none"} /> Like
          </Button>
          {!!likes.length && (
            <div className="flex items-center pl-1 cursor-pointer" onClick={() => setShowLikes(true)}>
              <p className="text-xs text-muted-foreground">
                {likes.length} {likes.length === 1 ? "like" : "likes"}
              </p>
            </div>
          )}
        </div>
        <Button variant="ghost" size="sm" onClick={() => setShowCommentBox(!showCommentBox)} className="text-sm text-stone-600">
          <MessageCircle size={16} className="mr-2" />
          Comment
        </Button>
      </div>

      {!!comments.length && (
        <div className="bg-stone-50 rounded-lg px-4 py-2 -mx-1 mt-1">
          {comments.slice(0, 2).map((comment, index) => renderComment(comment, index))}

          {comments.length > 2 && (
            <button onClick={() => setShowAllComments(true)} className="text-xs text-stone-500 hover:underline mt-2">
              View all {comments.length} comments
            </button>
          )}
        </div>
      )}

      {showCommentBox && (
        <div className="mt-2 flex space-x-2">
          <Input
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment..."
            className="flex-grow"
          />
          <Button onClick={handleAddComment}>Post</Button>
        </div>
      )}

      <Dialog open={showAllComments} onOpenChange={setShowAllComments}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Comments</DialogTitle>
          </DialogHeader>
          <div className="max-h-[60vh] overflow-y-auto">{comments.map((comment, index) => renderComment(comment, index))}</div>
        </DialogContent>
      </Dialog>

      <Dialog open={showLikes} onOpenChange={setShowLikes}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Likes</DialogTitle>
          </DialogHeader>
          <div className="max-h-[60vh] overflow-y-auto">
            {likes.map((like, index) => (
              <div key={index} className="flex items-center space-x-2 mt-2">
                <Avatar name={like.profileName} size="22" round textSizeRatio={2} />
                <div className="text-sm">{like.profileName}</div>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
