import { Id } from "../../convex/_generated/dataModel";
import { api } from "../../convex/_generated/api";
import { useQuery } from "convex/react";
import { useParams } from "react-router-dom";
import { LikesAndComments } from "@/components/LikesAndComments";
import Avatar from "react-avatar";
import { formatDistanceToNow } from "date-fns";

const ActivityPage = () => {
  const { id } = useParams<{ id: string }>();
  const completion = useQuery(api.completions.getCompletionSocialData, { id: id as Id<"completions"> });

  if (id === undefined) {
    return <div>Invalid activity ID</div>;
  }

  if (completion === undefined) {
    return <div>Loading...</div>;
  }

  if (completion === null) {
    return <div>Activity not found</div>;
  }

  return (
    <div className="p-7 max-w-xl mx-auto sm:pt-12">
      <div>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <Avatar name={completion.profile?.name} size="22" round textSizeRatio={2} />
            <div className="text-sm text-muted-foreground">{completion.profile?.name}</div>
          </div>
          <div className="text-xs text-muted-foreground">{formatDistanceToNow(completion.completion.date)} ago</div>
        </div>
        <p className="text-sm text-stone-800 px-1">
          Completed: <b>{completion.habit?.name}</b>
        </p>
      </div>
      <LikesAndComments completionId={id as Id<"completions">} />
    </div>
  );
};

export default ActivityPage;
