import { Card } from "@/components/ui/card";
import { api } from "../../convex/_generated/api";
import { useMutation, useQuery } from "convex/react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Id } from "convex/_generated/dataModel";
import { getStreakColor } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { CheckCheck, Crown, Flame, MoveLeft, UserPlus } from "lucide-react";
import { toast } from "@/hooks/use-toast";
import { useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import Avatar from "react-avatar";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";

const GroupDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const leaveGroup = useMutation(api.groups.leave);
  const groups = useQuery(api.groups.getGroups, {});
  const groupMembersAndStats = useQuery(api.groups.getGroupMembersAndStats, { groupId: id as Id<"groups"> });
  const groupStreak = useQuery(api.groups.getGroupStreak, { groupId: id as Id<"groups"> });
  const group = groups?.find((group) => group?._id === id);

  const groupCompletions = groupMembersAndStats?.reduce((acc, member) => acc + member.completions, 0);

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [leaderboardType, setLeaderboardType] = useState<"completions" | "streak">("completions");

  const sortedMembers = groupMembersAndStats?.sort((a, b) => {
    if (leaderboardType === "completions") {
      return b.completions - a.completions;
    }
    return b.streak - a.streak;
  });

  const handleLeaveGroup = async () => {
    setIsConfirmationOpen(false);
    try {
      await leaveGroup({ groupId: id as Id<"groups"> });
      toast({
        title: "You have left the group",
      });
      navigate("/");
    } catch (error) {
      console.error("Error leaving group:", error);
      toast({
        title: "Failed to leave group",
        description: "Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleShareGroup = async () => {
    const shareData = {
      text: `Building habits is easier with friends! You can join my Superhabits group "${group?.name}" using the code ${group?.joinCode} or by clicking this link: https://superhabits.link/group?code=${group?.joinCode}`,
    };

    if (navigator.canShare && navigator.canShare(shareData)) {
      await navigator.share(shareData);
    } else {
      navigator.clipboard.writeText(`${group?.joinCode}`);
      toast({
        title: "Group code copied to clipboard",
        description: "You can share it with others via email or other apps.",
        variant: "default",
      });
    }
  };

  if (!group?.type) {
    return null;
  }

  if (group?.type === "company") {
    return (
      <div className="p-5 pt-2 max-w-4xl mx-auto sm:pt-12">
        <Button onClick={() => navigate("/groups")} variant="ghost" className="text-primary -ml-1">
          <MoveLeft className="mr-2" size="16" />
          My Groups
        </Button>
        <h1 className="text-xl font-bold p-2 pb-1">{group?.name}</h1>
        <p className="text-sm text-stone-500 p-2 pb-3">Join Code: {group?.joinCode}</p>
        <Button onClick={() => setIsConfirmationOpen(true)} variant="secondary" className="w-full mt-4 rounded-full">
          Leave Group
        </Button>

        <Dialog open={isConfirmationOpen} onOpenChange={setIsConfirmationOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="pb-3">Leave Group</DialogTitle>
              <DialogDescription>
                Are you sure you want to leave this group? You'll lose access to the group's activities and progress.
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button variant="outline" onClick={() => setIsConfirmationOpen(false)}>
                Cancel
              </Button>
              <Button className="mb-3 sm:mb-0" variant="destructive" onClick={handleLeaveGroup}>
                Leave Group
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    );
  }

  return (
    <div className="p-5 pt-2 max-w-4xl mx-auto sm:pt-12">
      <Button onClick={() => navigate("/groups")} variant="ghost" className="text-primary -ml-1">
        <MoveLeft className="mr-2" size="16" />
        My Groups
      </Button>
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-bold p-2 pb-1">{group?.name}</h1>
        <Button onClick={handleShareGroup} className="rounded-full px-4" size="sm">
          <UserPlus size="16" className="mr-2" /> Invite
        </Button>
      </div>
      <p className="text-sm text-stone-500 p-2 pb-3">Join Code: {group?.joinCode}</p>
      <div className="grid grid-cols-2 gap-2">
        <Card className="py-3 px-4 sm:p-6 space-y-1">
          <p className="text-xs text-stone-500">Group Streak</p>
          <p className="text-lg font-semibold">{groupStreak}</p>
        </Card>
        <Card className="py-3 px-4 sm:p-6 space-y-1">
          <p className="text-xs text-stone-500">Group Completions</p>
          <p className="text-lg font-semibold">{groupCompletions}</p>
        </Card>
      </div>
      <div className="pt-2">
        <div className="flex items-center justify-between p-1 px-2">
          <p className="text-sm text-stone-500">Leaderboard</p>
          <ToggleGroup
            type="single"
            value={leaderboardType}
            onValueChange={(value) => setLeaderboardType(value as "completions" | "streak")}
          >
            <ToggleGroupItem value="completions" size="sm">
              <CheckCheck size="16" className={`${leaderboardType === "completions" ? "text-primary" : ""}`} />
            </ToggleGroupItem>
            <ToggleGroupItem value="streak" size="sm">
              <Flame size="16" className={`${leaderboardType === "streak" ? "text-primary" : ""}`} />
            </ToggleGroupItem>
          </ToggleGroup>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
          {sortedMembers?.map((member, index) => {
            const streakColor = getStreakColor(member.streak);
            return (
              <Card key={index} className="py-4 px-5">
                <div className="flex items-start justify-between">
                  <div className="flex items-center space-x-2 mb-2">
                    <Avatar name={member.name} size="28" round textSizeRatio={2} />
                    <p className="font-semibold">{member.name}</p>
                  </div>
                  {index === 0 && <Crown size="18" className="text-amber-400" />}
                  {index === 1 && <Crown size="18" className="text-gray-400" />}
                  {index === 2 && <Crown size="18" className="text-yellow-600" />}
                </div>

                <div className="flex space-x-2 pt-2 -mx-1">
                  <Badge className={`text-xs text-primary-600 whitespace-nowrap py-1.5 leading-none bg-primary-100`}>
                    <CheckCheck size="14" className="mr-1 mb-[1px]" />
                    Completions: {member.completions}
                  </Badge>
                  <Badge
                    className={`text-xs text-white whitespace-nowrap py-1.5 leading-none
              ${
                streakColor === "stone"
                  ? "bg-stone-400"
                  : streakColor === "blue"
                    ? "bg-blue-400"
                    : streakColor === "cyan"
                      ? "bg-cyan-400"
                      : streakColor === "emerald"
                        ? "bg-emerald-400"
                        : streakColor === "lime"
                          ? "bg-lime-400"
                          : streakColor === "amber"
                            ? "bg-amber-400"
                            : "bg-red-400"
              }`}
                  >
                    <Flame size="14" className="mr-1 mb-[1px]" />
                    Streak: {member.streak}
                  </Badge>
                </div>
                <p className="text-xs text-stone-500 px-1 py-1 pt-4">Top Superhabits</p>
                {member.topSuperhabits?.map((superhabit) => (
                  <Badge
                    key={superhabit.superhabitId}
                    className={`text-xs text-stone-600 whitespace-nowrap py-1.5 leading-none bg-stone-100 p-1 px-2 mr-1 mb-[2px] rounded-md`}
                  >
                    {superhabit.superhabitName}
                  </Badge>
                ))}
                {/* <p className="text-xs text-stone-500 px-1 py-1 pt-3">Working On</p>
                {member.bottomSuperhabits?.map((superhabit) => (
                  <Badge
                    key={superhabit.superhabitId}
                    className={`text-xs text-stone-600 whitespace-nowrap py-1.5 leading-none bg-stone-100 p-1 px-2 mr-1 mb-[2px] rounded-md`}
                  >
                    {superhabit.superhabitName}
                  </Badge>
                ))} */}
              </Card>
            );
          })}
        </div>
      </div>
      <Button onClick={() => setIsConfirmationOpen(true)} variant="secondary" className="w-full mt-8 rounded-full">
        Leave Group
      </Button>

      <Dialog open={isConfirmationOpen} onOpenChange={setIsConfirmationOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="pb-3">Leave Group</DialogTitle>
            <DialogDescription>
              Are you sure you want to leave this group? You'll lose access to the group's activities and progress.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsConfirmationOpen(false)}>
              Cancel
            </Button>
            <Button className="mb-3 sm:mb-0" variant="destructive" onClick={handleLeaveGroup}>
              Leave Group
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default GroupDetailsPage;
