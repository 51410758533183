import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Loader2, Plus, UserPlus } from "lucide-react";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { toast } from "@/hooks/use-toast";
import { useNavigate } from "react-router-dom";
import { Id } from "../../convex/_generated/dataModel";

interface CreateGroupCardProps {
  variant?: "card" | "button";
  className?: string;
}

export const CreateGroupCard = ({ variant = "card", className = "" }: CreateGroupCardProps) => {
  const navigate = useNavigate();
  const createGroup = useMutation(api.groups.create);
  const profile = useQuery(api.profiles.get);

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [newGroupId, setNewGroupId] = useState<Id<"groups"> | null>(null);
  const [newGroupCode, setNewGroupCode] = useState<string>("");
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    if (profile?.name) {
      setNewGroupName(`${profile.name}'s Group`);
    }
  }, [profile?.name]);

  const handleCreateGroup = async () => {
    try {
      setIsCreating(true);
      const { groupId, code } = await createGroup({
        name: newGroupName,
        type: "personal",
      });
      setCreateDialogOpen(false);
      setNewGroupId(groupId);
      setNewGroupCode(code);
      setSuccessDialogOpen(true);
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Failed to create group",
        variant: "destructive",
      });
    } finally {
      setIsCreating(false);
    }
  };

  const handleShareGroup = async () => {
    const shareData = {
      text: `Building habits is easier with friends! You can join my Superhabits group "${newGroupName}" using the code ${newGroupCode} or by clicking this link: https://superhabits.link/group?code=${newGroupCode}`,
    };

    if (navigator.canShare && navigator.canShare(shareData)) {
      await navigator.share(shareData);
    } else {
      navigator.clipboard.writeText(`${newGroupCode}`);
      toast({
        title: "Group code copied to clipboard",
        description: "You can share it with others via email or other apps.",
        variant: "default",
      });
    }
  };

  if (variant === "button") {
    return (
      <>
        <Button onClick={() => setCreateDialogOpen(true)} className={className} size="sm">
          <Plus className="mr-2" size="16" />
          Create Group
        </Button>
        {renderDialogs()}
      </>
    );
  }

  return (
    <>
      <Card className={`bg-cyan-600 shadow-lg shadow-cyan-600/20 border-4 border-cyan-500 ${className}`}>
        <CardContent className="flex flex-col space-y-4 h-full p-6 py-5">
          <h2 className="text-lg font-semibold text-white">Building habits is easier with friends!</h2>
          <p className="text-white text-sm">
            Create a group for your family, friends or coworkers. Share progress and help each other stay motivated.
          </p>
          <div className="flex justify-end">
            <Button onClick={() => setCreateDialogOpen(true)} className="bg-white text-cyan-600 hover:bg-cyan-50 rounded-full px-6">
              <Plus className="mr-2" size="16" />
              Create Group
            </Button>
          </div>
        </CardContent>
      </Card>
      {renderDialogs()}
    </>
  );

  function renderDialogs() {
    return (
      <>
        <Dialog open={createDialogOpen} onOpenChange={setCreateDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Create New Group</DialogTitle>
            </DialogHeader>
            <div className="flex flex-col gap-4">
              <Input
                placeholder="Group Name"
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                disabled={isCreating}
              />
              <Button onClick={handleCreateGroup} disabled={!newGroupName.trim() || isCreating}>
                {isCreating ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Creating...
                  </>
                ) : (
                  "Create Group"
                )}
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog open={successDialogOpen} onOpenChange={setSuccessDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Group Created!</DialogTitle>
              <DialogDescription>
                Your group has been created successfully. Would you like to invite members or go to the group page?
              </DialogDescription>
            </DialogHeader>
            <div className="flex flex-col gap-3 pt-2">
              <Button onClick={handleShareGroup} className="w-full">
                <UserPlus className="w-4 h-4 mr-2" />
                Invite Members
              </Button>
              <Button
                onClick={() => {
                  setSuccessDialogOpen(false);
                  navigate(`/groups/${newGroupId}`);
                }}
                variant="secondary"
                className="w-full"
              >
                Go to Group
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
};
