import { Button } from "@/components/ui/button";
import { api } from "../../convex/_generated/api";
import { useMutation } from "convex/react";
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

const FeedbackPage = () => {
  const submitFeedback = useMutation(api.feedback.submit);
  const { toast } = useToast();
  const [feedback, setFeedback] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await submitFeedback({ feedback, email });
      toast({
        title: "Feedback submitted successfully!",
        description: "We really appreciate your feedback and will respond to any questions as soon as possible.",
        duration: 5000,
      });
      setFeedback(""); // Clear the feedback input
    } catch (error) {
      console.log(error);
      toast({
        title: "Failed to submit feedback",
        description: "Please try again.",
        duration: 5000,
      });
    }
  };

  return (
    <div className="max-w-xl mx-auto p-6 sm:pt-24">
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <h1 className="text-xl font-bold">Leave Feedback</h1>
        <p className="text-sm text-stone-500">
          We're always looking to improve. Please share any questions, suggestions, or issues you're experiencing.
        </p>
        <textarea
          className="w-full p-2 border border-stone-200 rounded-lg text-sm h-[200px]"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
        <div className="flex flex-col gap-1">
          <Label className="text-sm text-stone-500">Email Address</Label>
          <Input
            className="w-full p-2 border border-stone-200 rounded-lg text-sm"
            placeholder="Your email (optional)"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Label className="text-xs text-stone-500">Please provide your email if you'd like us to respond</Label>
        </div>
        <Button className="rounded-lg" type="submit" disabled={!feedback}>
          Submit Feedback
        </Button>
      </form>
    </div>
  );
};

export default FeedbackPage;
