import { useEffect, useMemo, useState } from "react";
import { api } from "../../convex/_generated/api";
import { useMutation, useQuery } from "convex/react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";

const commonTimezones = [
  "Pacific/Midway",
  "Pacific/Honolulu",
  "America/Anchorage",
  "America/Los_Angeles",
  "America/Vancouver",
  "America/Phoenix",
  "America/Denver",
  "America/Chicago",
  "America/Mexico_City",
  "America/New_York",
  "America/Toronto",
  "America/Bogota",
  "America/Caracas",
  "America/Santiago",
  "America/St_Johns",
  "America/Sao_Paulo",
  "America/Argentina/Buenos_Aires",
  "Atlantic/Azores",
  "Europe/London",
  "Europe/Paris",
  "Europe/Berlin",
  "Europe/Rome",
  "Europe/Moscow",
  "Africa/Cairo",
  "Africa/Johannesburg",
  "Asia/Jerusalem",
  "Asia/Dubai",
  "Asia/Karachi",
  "Asia/Kolkata",
  "Asia/Bangkok",
  "Asia/Singapore",
  "Asia/Tokyo",
  "Australia/Perth",
  "Australia/Sydney",
  "Pacific/Auckland",
];

const SettingsPage = () => {
  const profile = useQuery(api.profiles.get);
  const updateProfile = useMutation(api.profiles.update);
  const { toast } = useToast();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [timeZone, setTimeZone] = useState("");
  const [isFormDirty, setIsFormDirty] = useState(false);

  const timezoneOptions = useMemo(() => {
    const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (detectedTimeZone && !commonTimezones.includes(detectedTimeZone)) {
      return [...commonTimezones, detectedTimeZone].sort();
    }
    return commonTimezones;
  }, []);

  useEffect(() => {
    if (profile) {
      setName(profile.name);
      setTimeZone(profile.timeZone);
      setIsSubscribed(profile.subscribed);
    }
  }, [profile]);

  useEffect(() => {
    // Automatically detect the user's timezone
    const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(detectedTimeZone);
  }, []);

  const validateName = (value: string) => {
    if (value.length < 2) {
      setNameError("Name must be at least 2 characters.");
    } else {
      setNameError("");
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
    validateName(value);
    setIsFormDirty(true);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (name.length >= 2) {
      updateProfile({ name, subscribed: isSubscribed, timeZone })
        .then(() => {
          setIsFormDirty(false);
          toast({
            title: "Profile updated",
            duration: 1000,
          });
        })
        .catch((error) => {
          console.error("Failed to update profile:", error);
          toast({
            title: "Update failed",
            description: "There was an error updating your profile. Please try again.",
            variant: "destructive",
          });
        });
    } else {
      validateName(name);
    }
  };

  return (
    <div className="space-y-6 p-6 max-w-xl mx-auto sm:pt-24">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-stone-700 mb-1 ml-1">
            Your First Name
          </label>
          <Input id="name" placeholder="Enter your name" value={name} onChange={handleNameChange} className="w-full" />
          {nameError && <p className="text-red-500 text-sm mt-1">{nameError}</p>}
        </div>

        <div>
          <label htmlFor="timeZone" className="block text-sm font-medium text-stone-700 mb-1 ml-1">
            Time Zone
          </label>
          <Select
            onValueChange={(value) => {
              setTimeZone(value);
              setIsFormDirty(true);
            }}
            value={timeZone}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select your time zone" />
            </SelectTrigger>
            <SelectContent>
              {timezoneOptions.map((tz) => (
                <SelectItem key={tz} value={tz}>
                  {tz}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="px-1">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="terms"
              checked={isSubscribed}
              onCheckedChange={(checked) => {
                setIsSubscribed(checked as boolean);
                setIsFormDirty(true);
              }}
            />
            <Label htmlFor="terms" className="text-sm">
              I agree to receive SMS updates
            </Label>
          </div>
          <p className="text-xs text-stone-500 mt-2">
            I understand that message and data rates may apply. I will receive up to 2 messages per day with reminders to complete
            my daily habits. I can text STOP to unsubscribe at any time.
          </p>
        </div>

        <div className="flex justify-end">
          <Button type="submit" className="rounded-xl" disabled={!isFormDirty}>
            Save Changes
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SettingsPage;
