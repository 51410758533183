import { useState, useCallback, useEffect, useMemo } from "react";
import { Check, CheckCheck, Flame } from "lucide-react";
import { Badge } from "./ui/badge";
import { Card, CardDescription, CardHeader, CardTitle } from "./ui/card";
import { Doc } from "../../convex/_generated/dataModel";
import { useMutation, useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { calculateStreak } from "../../convex/utils";
import HabitWeeklyCalendar from "./HabitWeeklyCalendar";
import { Button } from "./ui/button";
import { getStreakColor } from "@/lib/utils";

const HabitCard = ({ habit }: { habit: Doc<"habits"> }) => {
  const submitCompletion = useMutation(api.completions.create);
  const removeCompletion = useMutation(api.completions.removeCompletion);
  const completions = useQuery(api.completions.get, { habitId: habit._id });
  const profile = useQuery(api.profiles.get);

  // Add state for optimistic UI update
  const [isOptimisticallyCompleted, setIsOptimisticallyCompleted] = useState<boolean | null>(null);

  const streak = useMemo(() => calculateStreak(completions || [], profile?.timeZone || "America/New_York"), [completions, profile]);
  const isCompletedToday = useMemo(() => {
    if (!completions) return false;
    return completions.some((completion) => new Date(completion._creationTime).toDateString() === new Date().toDateString());
  }, [completions]);

  // Update optimistic state when server state changes
  useEffect(() => {
    setIsOptimisticallyCompleted(null);
  }, [isCompletedToday]);

  // Combine server state with optimistic state
  const isButtonCompleted = isOptimisticallyCompleted ?? isCompletedToday;

  const handleComplete = useCallback(() => {
    const now = Date.now();
    const timeZone = profile?.timeZone || "America/New_York";

    // Optimistically update the button state
    setIsOptimisticallyCompleted(!isButtonCompleted);

    if (isButtonCompleted) {
      removeCompletion({ habitId: habit._id, date: now, timeZone }).catch(() => {
        // If the mutation fails, revert the optimistic update
        setIsOptimisticallyCompleted(true);
      });
    } else {
      submitCompletion({ habitId: habit._id, date: now, timeZone }).catch(() => {
        // If the mutation fails, revert the optimistic update
        setIsOptimisticallyCompleted(false);
      });
    }
  }, [isButtonCompleted, habit._id, profile?.timeZone, removeCompletion, submitCompletion]);

  const streakColor = getStreakColor(streak);

  return (
    <div className="relative">
      <Card className={`${isCompletedToday ? "border-primary-200" : ""}`}>
        <CardHeader className="p-6 px-7">
          <div className="-mx-1 pb-2 flex items-center justify-between">
            <HabitWeeklyCalendar completions={completions || []} />
            <Button
              size="icon"
              onClick={handleComplete}
              className={`${isButtonCompleted ? "bg-primary-400 hover:bg-primary-500 focus:bg-primary-400" : "bg-white hover:bg-stone-100 border border-stone-300 focus:border-stone-400 focus:bg-white"} rounded-full w-8 h-8`}
            >
              <Check size="16" className={`${isButtonCompleted ? "text-white" : "text-stone-400"}`} />
            </Button>
          </div>
          <CardTitle className="text-base font-semibold">{habit.name}</CardTitle>
          <CardDescription className="text-xs text-stone-500">{habit.description}</CardDescription>
          <div className="flex space-x-2 pt-2 -mx-1">
            <Badge className={`text-xs text-primary-600 whitespace-nowrap py-1.5 leading-none bg-primary-100`}>
              <CheckCheck size="14" className="mr-1 mb-[1px]" />
              Completions: {completions?.length}
            </Badge>
            <Badge
              className={`text-xs text-white whitespace-nowrap py-1.5 leading-none
              ${
                streakColor === "stone"
                  ? "bg-stone-400"
                  : streakColor === "blue"
                    ? "bg-blue-400"
                    : streakColor === "cyan"
                      ? "bg-cyan-400"
                      : streakColor === "emerald"
                        ? "bg-emerald-400"
                        : streakColor === "lime"
                          ? "bg-lime-400"
                          : streakColor === "amber"
                            ? "bg-amber-400"
                            : "bg-red-400"
              }`}
            >
              <Flame size="14" className="mr-1 mb-[1px]" />
              Streak: {streak}
            </Badge>
          </div>
        </CardHeader>
      </Card>
    </div>
  );
};

export default HabitCard;
