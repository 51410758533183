import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import { Share2, TrendingDown, TrendingUp } from "lucide-react";
import { toast } from "@/hooks/use-toast";
import { usePostHog } from "posthog-js/react";
import { getScoreColor } from "@/lib/utils";
import { Badge } from "./ui/badge";

const ScoreCard = () => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const scores = useQuery(api.assessments.getScores);
  const scoreChange = useQuery(api.assessments.getScoreChange);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const profile = useQuery(api.profiles.get);

  const averageScore = useMemo(() => {
    if (!scores) return null;
    return (scores.reduce((acc, score) => acc + score.score, 0) / scores.length).toFixed(1);
  }, [scores]);

  const averageScoreChange = useMemo(() => {
    if (!scoreChange) return null;
    return (scoreChange.reduce((acc, score) => acc + (Number(score.difference) || 0), 0) / scoreChange.length).toFixed(1);
  }, [scoreChange]);

  const handleNavigate = (superhabitId: string) => {
    navigate(`/superhabit/${superhabitId}`);
  };

  const getScoreChangeIcon = (superhabitId: string) => {
    const individualScoreChange = scoreChange?.find((score) => score.superhabitId === superhabitId);
    if (!individualScoreChange) return null;
    if (Number(individualScoreChange.difference) > 0) {
      return <TrendingUp className="w-3 h-3 text-green-500 mr-1" />;
    } else if (Number(individualScoreChange.difference) < 0) {
      return <TrendingDown className="w-3 h-3 text-red-500 mr-1" />;
    } else {
      return null;
    }
  };

  const getScoreChangeBadge = (superhabitId: string) => {
    const individualScoreChange = scoreChange?.find((score) => score.superhabitId === superhabitId);
    if (!individualScoreChange) return null;
    if (Number(individualScoreChange.difference) > 0) {
      return (
        <Badge className="bg-green-100 text-green-500 text-xs mr-1">
          <TrendingUp className="w-3 h-3 mr-1" />
          {Math.abs(Number(individualScoreChange.difference)).toFixed(1)}
        </Badge>
      );
    } else if (Number(individualScoreChange.difference) < 0) {
      return (
        <Badge className="bg-red-100 text-red-500 text-xs mr-1">
          <TrendingDown className="w-3 h-3 mr-1" />
          {Math.abs(Number(individualScoreChange.difference)).toFixed(1)}
        </Badge>
      );
    } else {
      return null;
    }
  };

  const handleShare = async () => {
    if (!scores || scores.length === 0) return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Increase canvas size and add more padding
    const padding = 60;
    canvas.width = 800;
    canvas.height = 650;

    // Try to load Arvo font
    let fontFamily = "Arvo, Arial, sans-serif";
    try {
      await document.fonts.load("16px Arvo");
      if (!document.fonts.check("16px Arvo")) {
        console.warn("Arvo font not available, falling back to system fonts");
        fontFamily = "Arial, sans-serif";
      }
    } catch (error) {
      console.warn("Error loading Arvo font, falling back to system fonts:", error);
      fontFamily = "Arial, sans-serif";
    }

    // Background
    ctx.fillStyle = "#f3f4f6";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Title
    ctx.fillStyle = "#111827";
    ctx.font = `bold 36px ${fontFamily}`;
    ctx.fillText(`${profile?.name ? profile.name + "'s" : "Your"} Top 5 Superhabits`, padding, padding + 25);

    // Superhabits and scores
    const top5Scores = scores.sort((a, b) => b.score - a.score).slice(0, 5);
    top5Scores.forEach((score, index) => {
      const y = padding + 100 + index * 80;

      // Superhabit name
      ctx.fillStyle = "#4b5563";
      ctx.font = `24px ${fontFamily}`;
      ctx.fillText(score.superhabitName, padding, y);

      // Score
      ctx.fillStyle = getScoreColor(score.score);
      ctx.font = `bold 24px ${fontFamily}`;
      ctx.fillText(score.score.toFixed(1), canvas.width - padding - 40, y);

      // Progress bar
      const barWidth = canvas.width - padding * 2;
      const barHeight = 12;
      ctx.fillStyle = "#e5e7eb";
      ctx.fillRect(padding, y + 15, barWidth, barHeight);
      ctx.fillStyle = "#5EC26A";
      ctx.fillRect(padding, y + 15, (score.score / 7) * barWidth, barHeight);
    });

    // App name and URL
    ctx.fillStyle = "#6b7280";
    ctx.font = `24px ${fontFamily}`;
    ctx.fillText("Find yours at superhabits.app", padding, canvas.height - padding);

    try {
      const imageBlob = await new Promise<Blob>((resolve) => canvas.toBlob((blob) => resolve(blob!)));
      const imageFile = new File([imageBlob], "superhabits-scores.png", { type: "image/png" });

      const shareData = {
        text: "Check out my top 5 Superhabits! You can find yours at https://superhabits.link/scores",
        files: [imageFile],
      };

      if (navigator.canShare && navigator.canShare(shareData)) {
        try {
          await navigator.share(shareData);
          posthog.capture("user shared scores");
        } catch (error) {
          if (error instanceof DOMException && error.name === "AbortError") {
            console.log("Share cancelled by user");
            // Do nothing, as this is not an error condition
          } else {
            throw error; // Re-throw other errors to be caught by the outer catch block
          }
        }
      } else {
        // Fallback for desktop or browsers that don't support Web Share API
        const imageUrl = URL.createObjectURL(imageBlob);
        const link = document.createElement("a");
        link.href = imageUrl;
        link.download = "superhabits-scores.png";
        link.click();
        URL.revokeObjectURL(imageUrl);

        toast({
          title: "Image saved!",
          description: "Your Superhabits scores image has been saved.",
        });
        posthog.capture("user shared scores");
      }
    } catch (error) {
      console.error("Error sharing:", error);
      toast({
        title: "Sharing failed",
        description: "Unable to generate or share the image. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="">
      <CardHeader>
        <div className="flex items-center justify-between">
          <CardTitle className="text-lg font-semibold">Superhabits Score</CardTitle>
          <div className="flex items-center gap-2">
            {averageScoreChange && (
              <Badge
                className={`text-xs font-bold ${Number(averageScoreChange) > 0 ? "bg-green-100 text-green-500" : "bg-red-100 text-red-500"}`}
              >
                {Number(averageScoreChange) > 0 ? (
                  <TrendingUp className="w-3 h-3 mr-1" />
                ) : (
                  <TrendingDown className="w-3 h-3 mr-1" />
                )}
                {Math.abs(Number(averageScoreChange)).toFixed(1)}
              </Badge>
            )}
            <div className="text-lg font-semibold text-primary-600 whitespace-nowrap">{averageScore} / 7</div>
          </div>
        </div>
      </CardHeader>
      <CardContent className="-mt-2">
        <div>
          {scores?.slice(0, 5).map((score) => (
            <div key={score.superhabitId} className="flex items-center justify-between space-y-1">
              <div className="text-sm text-stone-600">{score.superhabitName}</div>
              <div className="flex items-center gap-1">
                {getScoreChangeIcon(score.superhabitId)}
                <div className={`${getScoreColor(score.score)} font-semibold text-sm`}>{score.score.toFixed(1)}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="border-b border-stone-200 border-dotted border-b-2 my-2" />
        <div>
          {scores?.slice(scores.length - 5, scores.length).map((score) => (
            <div key={score.superhabitId} className="flex items-center justify-between space-y-1">
              <div className="text-sm text-stone-600">{score.superhabitName}</div>
              <div className="flex items-center gap-1">
                {getScoreChangeIcon(score.superhabitId)}
                <div className={`${getScoreColor(score.score)} font-semibold text-sm`}>{score.score.toFixed(1)}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-between pt-5 -mx-1">
          <Button
            onClick={handleShare}
            className="rounded-full text-primary-500 border-primary-300 hover:text-primary-600"
            variant="outline"
          >
            <Share2 className="w-4 h-4 mr-2 text-primary-400" />
            Share top 5
          </Button>
          <Dialog>
            <DialogTrigger className="text-sm text-primary-600 hover:text-primary-600 p-2">View All</DialogTrigger>
            <DialogContent aria-describedby="superhabits-scores">
              <DialogHeader>
                <DialogTitle className="text-lg font-semibold pb-2">Superhabits Scores</DialogTitle>
              </DialogHeader>
              <div className="flex flex-col gap-2 overflow-y-auto max-h-[70vh]">
                {scores?.map((score) => (
                  <div
                    key={score.superhabitId}
                    onClick={() => handleNavigate(score.superhabitId)}
                    className="cursor-pointer flex items-center justify-between border-b-2 border-stone-200 border-dotted pb-2"
                  >
                    <div className="text-primary-600">{score.superhabitName}</div>
                    <div className="flex items-center gap-1">
                      {getScoreChangeBadge(score.superhabitId)}
                      <div className={`${getScoreColor(score.score)} font-semibold text-sm`}>{score.score.toFixed(1)}</div>
                    </div>
                  </div>
                ))}
              </div>
            </DialogContent>
          </Dialog>
        </div>
        <canvas ref={canvasRef} style={{ display: "none" }} />
      </CardContent>
    </Card>
  );
};

export default ScoreCard;
