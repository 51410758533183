import Chat from "@/components/Chat";
import { Button } from "@/components/ui/button";
import { api } from "../../convex/_generated/api";
import { useMutation, useQuery } from "convex/react";
import { ArrowLeft, ArrowRight, Circle, Share2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { getScoreColor } from "@/lib/utils";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { usePostHog } from "posthog-js/react";
import { toast } from "@/hooks/use-toast";
import celebrationImage from "@/assets/celebration.png";

const InitialMessagePage = () => {
  const navigate = useNavigate();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const posthog = usePostHog();

  const completeOnboarding = useMutation(api.profiles.completeOnboarding);
  const scores = useQuery(api.assessments.getScores);
  const profile = useQuery(api.profiles.get);
  const habits = useQuery(api.habits.get);

  const [resultsReviewed, setResultsReviewed] = useState(false);

  useEffect(() => {
    (async () => {
      if (habits && habits.length > 0) {
        await completeOnboarding();
        navigate("/messages");
      }
    })();
  }, [habits, completeOnboarding, navigate]);

  const handleCompleteOnboarding = async () => {
    await completeOnboarding();
    navigate("/");
  };

  const handleShare = async () => {
    if (!scores || scores.length === 0) return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Increase canvas size and add more padding
    const padding = 60;
    canvas.width = 800;
    canvas.height = 650;

    // Try to load Arvo font
    let fontFamily = "Arvo, Arial, sans-serif";
    try {
      await document.fonts.load("16px Arvo");
      if (!document.fonts.check("16px Arvo")) {
        console.warn("Arvo font not available, falling back to system fonts");
        fontFamily = "Arial, sans-serif";
      }
    } catch (error) {
      console.warn("Error loading Arvo font, falling back to system fonts:", error);
      fontFamily = "Arial, sans-serif";
    }

    // Background
    ctx.fillStyle = "#f3f4f6";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Title
    ctx.fillStyle = "#111827";
    ctx.font = `bold 36px ${fontFamily}`;
    ctx.fillText(`${profile?.name ? profile.name + "'s" : "My"} Top 5 Superhabits`, padding, padding + 25);

    // Superhabits and scores
    const top5Scores = scores.sort((a, b) => b.score - a.score).slice(0, 5);
    top5Scores.forEach((score, index) => {
      const y = padding + 100 + index * 80;

      // Superhabit name
      ctx.fillStyle = "#4b5563";
      ctx.font = `24px ${fontFamily}`;
      ctx.fillText(score.superhabitName, padding, y);

      // Score
      ctx.fillStyle = getScoreColor(score.score);
      ctx.font = `bold 24px ${fontFamily}`;
      ctx.fillText(score.score.toFixed(1), canvas.width - padding - 40, y);

      // Progress bar
      const barWidth = canvas.width - padding * 2;
      const barHeight = 12;
      ctx.fillStyle = "#e5e7eb";
      ctx.fillRect(padding, y + 15, barWidth, barHeight);
      ctx.fillStyle = "#5EC26A";
      ctx.fillRect(padding, y + 15, (score.score / 7) * barWidth, barHeight);
    });

    // App name and URL
    ctx.fillStyle = "#6b7280";
    ctx.font = `24px ${fontFamily}`;
    ctx.fillText("Find yours at superhabits.app", padding, canvas.height - padding);

    try {
      const imageBlob = await new Promise<Blob>((resolve) => canvas.toBlob((blob) => resolve(blob!)));
      const imageFile = new File([imageBlob], "superhabits-scores.png", { type: "image/png" });

      const shareData = {
        text: "Check out my top 5 Superhabits! You can find yours at https://superhabits.link/scores",
        files: [imageFile],
      };

      if (navigator.canShare && navigator.canShare(shareData)) {
        try {
          await navigator.share(shareData);
          posthog.capture("user shared scores");
        } catch (error) {
          if (error instanceof DOMException && error.name === "AbortError") {
            console.log("Share cancelled by user");
            // Do nothing, as this is not an error condition
          } else {
            throw error; // Re-throw other errors to be caught by the outer catch block
          }
        }
      } else {
        // Fallback for desktop or browsers that don't support Web Share API
        const imageUrl = URL.createObjectURL(imageBlob);
        const link = document.createElement("a");
        link.href = imageUrl;
        link.download = "superhabits-scores.png";
        link.click();
        URL.revokeObjectURL(imageUrl);

        toast({
          title: "Image saved!",
          description: "Your Superhabits scores image has been saved.",
        });
        posthog.capture("user shared scores");
      }
    } catch (error) {
      console.error("Error sharing:", error);
      toast({
        title: "Sharing failed",
        description: "Unable to generate or share the image. Please try again.",
        variant: "destructive",
      });
    }
  };

  if (!resultsReviewed) {
    return (
      <div className="bg-stone-50 h-screen flex items-center justify-center overflow-y-auto">
        <div className="max-w-lg w-full mx-auto p-6 pt-0">
          <img src={celebrationImage} alt="celebration" className="w-20 h-auto mx-auto" />
          <Card className="w-full p-2">
            <CardHeader>
              <CardTitle className="text-xl font-semibold">Your top 5 superhabits</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col gap-2">
                {scores?.slice(0, 5).map((result, index) => (
                  <div
                    key={result.superhabitId}
                    className={`flex items-center justify-between ${
                      index < 4 ? "border-b-2 border-stone-100 pb-2 border-dashed" : ""
                    }`}
                  >
                    <div className="text-lg text-stone-700">{result.superhabitName}</div>
                    <div className={`text-lg ${getScoreColor(result.score)}`}>{result.score.toFixed(1)}</div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
          <div className="px-4 pt-4">
            <Button onClick={handleShare} className="mt-4 rounded-xl w-full">
              <Share2 className="w-4 h-4 mr-2" /> Share with friends
            </Button>
            <Button onClick={() => setResultsReviewed(true)} variant="outline" className="mt-3 rounded-xl w-full">
              Continue
              <ArrowRight className="w-4 h-4 ml-2" />
            </Button>
          </div>
        </div>
        <canvas ref={canvasRef} style={{ display: "none" }} />
      </div>
    );
  }

  return (
    <div className="bg-stone-50">
      <div className="p-2">
        <Button variant="ghost" className="rounded-full" size="sm" onClick={handleCompleteOnboarding}>
          <ArrowLeft className="w-4 h-4 mr-2" />
          Leave Chat
          {habits && habits.length > 0 && <Circle className="w-2 h-2 ml-2 text-teal-500 animate-ping fill-teal-500" />}
        </Button>
      </div>
      <Chat />
    </div>
  );
};

export default InitialMessagePage;
