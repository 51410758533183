import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import HabitCard from "@/components/HabitCard";
import ScoreCard from "@/components/ScoreCard";
import { Card, CardContent } from "@/components/ui/card";
import { Link } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import GroupFeed from "@/components/GroupFeed";

const Dashboard = () => {
  const habits = useQuery(api.habits.get);
  const mostRecentAssessmentDate = useQuery(api.assessments.getMostRecentAssessmentDate);
  const isTwoWeeksSinceLastAssessment = mostRecentAssessmentDate
    ? new Date(mostRecentAssessmentDate).getTime() < Date.now() - 14 * 24 * 60 * 60 * 1000
    : true;
  const promptAssessment = isTwoWeeksSinceLastAssessment;

  if (habits === undefined) {
    return (
      <div className="flex items-center justify-center h-screen">
        <HashLoader color={"#925743"} loading={true} size={6} aria-label="Loading..." data-testid="loader" />
      </div>
    );
  }

  return (
    <div className="space-y-6 p-4 pt-1 sm:pt-12 max-w-5xl mx-auto">
      <Tabs defaultValue="habits" className="w-full ">
        <div className="flex items-center justify-center">
          <TabsList className="w-full rounded-full mb-1 sm:w-[300px] sm:mb-4">
            <TabsTrigger value="habits" className="w-full rounded-full">
              Habits
            </TabsTrigger>
            <TabsTrigger value="groups" className="w-full rounded-full">
              Groups
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="groups">
          <GroupFeed />
        </TabsContent>
        <TabsContent value="habits">
          <div className="grid gap-3 sm:gap-4 sm:grid-cols-2">
            {habits?.length ? (
              <div className="flex flex-col gap-2 sm:gap-4">
                {habits?.map((habit) => <HabitCard key={habit._id} habit={habit} />)}
              </div>
            ) : (
              <Card className="flex flex-col border-dashed items-center justify-center space-y-3 text-center p-8">
                <h2 className="text-xl font-semibold">No habits yet</h2>
                <p className="text-muted-foreground text-sm">
                  The{" "}
                  <Link to="/messages" className="text-primary underline">
                    AI coach
                  </Link>{" "}
                  can add a habit for you
                </p>
              </Card>
            )}

            <div className="flex flex-col gap-3 sm:gap-4">
              {!!promptAssessment && (
                <Card className="bg-cyan-600 shadow-lg shadow-cyan-600/20 border-4 border-cyan-500">
                  <CardContent className="flex flex-col justify-center items-center space-y-4 h-full p-6">
                    <h2 className="text-center text-lg font-semibold text-white">It's time for your next assessment!</h2>
                    <Link to="/assessment">
                      <Button className="bg-white text-cyan-600 hover:bg-cyan-50 rounded-full px-8">Get Started</Button>
                    </Link>
                  </CardContent>
                </Card>
              )}
              <ScoreCard />
            </div>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Dashboard;
