import { Card } from "@/components/ui/card";
import { api } from "../../convex/_generated/api";
import { useMutation, useQuery } from "convex/react";
import { Button } from "@/components/ui/button";
import { useState, useEffect, useMemo } from "react";
import { ArrowLeft } from "lucide-react";
import { Id } from "../../convex/_generated/dataModel";
import { Progress } from "@/components/ui/progress";
import { usePostHog } from "posthog-js/react";

const introSteps = [
  <>
    <span className="text-2xl font-semibold text-stone-800">Welcome!</span>
    <br />
    <br />
    Personal growth is hard, and can be overwhelming. How do we make it easier?
    <br />
    <br />
    Habits is the answer. As books like Atomic Habits show, small, simple changes, repeated daily, take advantage of
    "neuroplasticity" to carve new pathways in your brain. Over time these create big changes in how you behave, which become so
    much part of you that you hardly have to think about them.
  </>,
  <>
    But <i>which</i> habits? Where should I focus first?
    <br />
    <br />
    Ancient philosophers identified – and modern science has confirmed – a specific set of superpower habits that make your life
    easier, more effective, happier and healthier.
  </>,
  <>
    Take our assessment to identify your areas for growth and learn how to cultivate these superhabits for overall personal
    development. <br />
    <br />
    The assessment will take about 5 to 10 minutes.
  </>,
];

const responseValues = [
  "7 - Agree strongly",
  "6 - Agree moderately",
  "5 - Agree a little",
  "4 - Neither agree nor disagree",
  "3 - Disagree a little",
  "2 - Disagree moderately",
  "1 - Disagree strongly",
];

interface Question {
  superhabitId: Id<"superhabits">;
  superhabitName: string;
  question: string;
  scoreRange: number[];
}

const calculateScore = (answers: Record<string, number>, questions: Question[]) => {
  const superhabitScores: Record<string, number[]> = {};

  // Group scores by superhabit
  Object.entries(answers).forEach(([key, value]) => {
    const [superhabitId] = key.split("-");
    if (!superhabitScores[superhabitId]) {
      superhabitScores[superhabitId] = [];
    }
    superhabitScores[superhabitId].push(value);
  });

  // Calculate average for each superhabit
  return Object.entries(superhabitScores).map(([superhabitId, scores]) => {
    const average = scores.reduce((sum, score) => sum + score, 0) / scores.length;
    const superhabit = questions.find((q: Question) => q.superhabitId === superhabitId);
    return {
      superhabitId: superhabitId as Id<"superhabits">,
      superhabitName: superhabit?.superhabitName ?? "",
      score: Number(average.toFixed(2)), // Round to 2 decimal places
    };
  });
};

const InitialAssessmentPage = () => {
  const posthog = usePostHog();
  const superhabitQuestions = useQuery(api.assessments.getQuestions);
  const submitAssessment = useMutation(api.assessments.submitInitialAssessment);
  const questions = useMemo(() => superhabitQuestions ?? [], [superhabitQuestions]);
  const [introStep, setIntroStep] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<Record<string, number>>({});
  const [isIntroComplete, setIsIntroComplete] = useState(false);
  const [fadeState, setFadeState] = useState("in");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Load saved progress from localStorage
    const savedProgress = localStorage.getItem("assessmentProgress");
    if (savedProgress) {
      const {
        introStep: savedIntroStep,
        currentQuestionIndex: savedQuestionIndex,
        answers: savedAnswers,
        isIntroComplete: savedIsIntroComplete,
      } = JSON.parse(savedProgress);
      setIntroStep(savedIntroStep);
      setCurrentQuestionIndex(savedQuestionIndex);
      setAnswers(savedAnswers);
      setIsIntroComplete(savedIsIntroComplete);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    // Save progress to localStorage whenever it changes
    if (!isLoading) {
      localStorage.setItem(
        "assessmentProgress",
        JSON.stringify({
          introStep,
          currentQuestionIndex,
          answers,
          isIntroComplete,
        })
      );
    }
  }, [introStep, currentQuestionIndex, answers, isIntroComplete, isLoading]);

  useEffect(() => {
    if (fadeState === "out") {
      const timer = setTimeout(() => {
        setFadeState("in");
      }, 300); // Match this with the transition duration
      return () => clearTimeout(timer);
    }
  }, [fadeState]);

  if (isLoading) {
    return <div>Loading...</div>; // Or a more sophisticated loading indicator
  }

  if (!isIntroComplete) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-stone-50 px-4 py-8">
        <Card className="w-full max-w-md space-y-8 p-6 min-h-[500px] flex flex-col justify-between">
          <div className="text-stone-700 leading-relaxed p-2">{introSteps[introStep]}</div>
          <div className="flex justify-between space-x-3 mt-auto">
            {introStep > 0 && (
              <Button variant="secondary" className="w-full rounded-xl" size="lg" onClick={() => setIntroStep(introStep - 1)}>
                Back
              </Button>
            )}
            {introStep < introSteps.length - 1 ? (
              <Button className="w-full rounded-xl" size="lg" onClick={() => setIntroStep(introStep + 1)}>
                Next
              </Button>
            ) : (
              <Button
                className="w-full rounded-xl"
                size="lg"
                onClick={() => {
                  setIsIntroComplete(true);
                  posthog.capture("user started assessment");
                }}
              >
                Start Assessment
              </Button>
            )}
          </div>
        </Card>
      </div>
    );
  }

  const handleAnswer = (superhabitId: string, value: number) => {
    const answerKey = `${superhabitId}-${currentQuestionIndex}`;
    const isLastQuestion = currentQuestionIndex === questions.length - 1;

    // Only trigger fade out if it's not the last question
    if (!isLastQuestion) {
      setFadeState("out");
    }

    setAnswers((prev) => ({ ...prev, [answerKey]: value }));

    // Only move to the next question if it's not the last one
    if (!isLastQuestion) {
      setTimeout(() => {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }, 300); // Match this with the transition duration
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setFadeState("out");
      setTimeout(() => {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
      }, 300); // Match this with the transition duration
    } else {
      setIsIntroComplete(false);
    }
  };

  const handleSubmit = async () => {
    const scores = calculateScore(answers, questions);

    const responses = Object.entries(answers).map(([key, value]) => {
      const questionIndex = key.split("-")[1];
      const question = questions[parseInt(questionIndex)];
      return {
        questionId: key,
        question: question.question,
        answer: value,
        superhabitId: question.superhabitId,
        superhabitName: question.superhabitName,
      };
    });
    await submitAssessment({ scores, responses });

    // Clear localStorage after successful submission
    localStorage.removeItem("assessmentProgress");
  };

  if (questions.length === 0) {
    return;
  }

  const currentQuestion = questions[currentQuestionIndex];
  const answerKey = currentQuestion ? `${currentQuestion.superhabitId}-${currentQuestionIndex}` : "";

  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-gray-100 px-6 py-8 overflow-auto">
        <div className="w-full max-w-md">
          <div
            className={`text-stone-700 leading-relaxed transition-opacity duration-300 ease-in-out ${
              fadeState === "in" ? "opacity-100" : "opacity-0"
            }`}
          >
            <p className="text-lg font-semibold mb-6 px-2">{currentQuestion.question}</p>
            <div className="space-y-3">
              {currentQuestion.scoreRange.map((value, index) => (
                <Button
                  key={index}
                  className="w-full rounded-xl justify-start p-6"
                  variant={answers[answerKey] === value ? "default" : "outline"}
                  size="lg"
                  onClick={() => handleAnswer(currentQuestion.superhabitId, value)}
                >
                  <span className="text-left text-lg">{responseValues[index]}</span>
                </Button>
              ))}
            </div>
          </div>
          <div className="flex justify-between space-x-3 mt-auto pt-6">
            <Button variant="ghost" className="w-full rounded-xl" onClick={handlePrevious}>
              <ArrowLeft className="w-4 h-4 mr-2" />
              Back
            </Button>
            {currentQuestionIndex === questions.length - 1 && (
              <Button className="w-full rounded-xl" onClick={handleSubmit} disabled={!answers[answerKey]}>
                Submit Answers
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 w-full">
        <Progress className="rounded-none h-2" value={(currentQuestionIndex / questions.length) * 100} />
      </div>
    </>
  );
};

export default InitialAssessmentPage;
