import { api } from "../../convex/_generated/api";
import { useQuery } from "convex/react";

const IntroductionPage = () => {
  const profile = useQuery(api.profiles.get);
  return (
    <div className="p-7 space-y-4 max-w-lg mx-auto">
      <h1 className="text-xl font-bold text-stone-800">Welcome, {profile?.name}!</h1>
      <p className="text-md text-stone-500">Personal growth is hard, and can be overwhelming. How do we make it easier?</p>
      <p className="text-md text-stone-500">
        Habits is the answer. As books like Atomic Habits show, small, simple changes, repeated daily, take advantage of
        "neuroplasticity" to carve new pathways in your brain. Over time these create big changes in how you behave, which become so
        much part of you that you hardly have to think about them.
      </p>
      <p className="text-md text-stone-500">But which habits? Where should I focus first?</p>
      <p className="text-md text-stone-500">
        Ancient philosophers identified – and modern science has confirmed – a specific set of superpower habits that make your life
        easier, more effective, happier and healthier.
      </p>
      <p className="text-md text-stone-500">
        The Superhabits app helps you identify the habits that will have the biggest impact on your life, and helps you cultivate
        them through simple, daily practices.
      </p>
      <p className="text-md text-stone-500">
        On average, people improve 20% on their initial superhabits assessment after just two weeks of daily practice.
      </p>
      <p className="text-md text-stone-500">Good luck, and enjoy the journey!</p>
      <div className="h-10" />
    </div>
  );
};

export default IntroductionPage;
