import { api } from "../../convex/_generated/api";
import { useQuery } from "convex/react";
import { Card } from "./ui/card";
import { formatDistanceToNow } from "date-fns";
import Avatar from "react-avatar";
import { Badge } from "./ui/badge";
import { Flame, Loader2 } from "lucide-react";
import { getStreakColor } from "@/lib/utils";
import { LikesAndComments } from "./LikesAndComments";
import GroupCard from "./GroupCard";
import { useNavigate } from "react-router-dom";
import { CreateGroupCard } from "./CreateGroupCard";

const GroupFeed = () => {
  const navigate = useNavigate();
  const groupFeed = useQuery(api.groups.getGroupFeed);
  const groups = useQuery(api.groups.getGroups, { groupType: "personal" });
  const isLoading = groupFeed === undefined || groups === undefined;

  if (isLoading) {
    return (
      <div className="text-sm text-stone-500 mt-2 leading-relaxed px-3 text-center h-24 flex items-center justify-center">
        <Loader2 size="16" className="animate-spin" />
      </div>
    );
  }

  if (!groups?.length) {
    return (
      <div className="mt-4 max-w-2xl mx-auto">
        <CreateGroupCard />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-1 pt-1 max-w-2xl mx-auto">
      <div className="flex justify-end pb-2 pr-1 sm:pr-2">
        <CreateGroupCard variant="button" className="rounded-full pr-5" />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        {groups?.map((group) => group && <GroupCard key={group._id} groupId={group._id} />)}
      </div>
      <p className="text-sm text-stone-500 mt-2 px-3">Feed</p>
      {groupFeed?.map((completion, i) => {
        const streakColor = getStreakColor(completion.streak);
        return (
          <Card key={i} className="py-4 px-5 pt-5 border-2 rounded-xl">
            <div onClick={() => navigate(`/post/${completion.id}`)} className="cursor-pointer">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-2">
                  <Avatar name={completion.userName} size="22" round textSizeRatio={2} />
                  <div className="text-sm text-muted-foreground">{completion.userName}</div>
                </div>
                <div className="text-xs text-muted-foreground">{formatDistanceToNow(completion.date)} ago</div>
              </div>
              <p className="text-sm text-stone-800 px-1">
                Completed: <b>{completion.habitName}</b>
              </p>
              {completion.streak > 2 && (
                <div className="flex justify-end">
                  <Badge
                    className={`text-xs text-white whitespace-nowrap py-1 mt-3 -mr-1 leading-none
                    ${
                      streakColor === "stone"
                        ? "bg-stone-400"
                        : streakColor === "blue"
                          ? "bg-blue-400"
                          : streakColor === "cyan"
                            ? "bg-cyan-400"
                            : streakColor === "emerald"
                              ? "bg-emerald-400"
                              : streakColor === "lime"
                                ? "bg-lime-400"
                                : streakColor === "amber"
                                  ? "bg-amber-400"
                                  : "bg-red-400"
                    }`}
                  >
                    <Flame size="14" className="mr-1 mb-[1px]" />
                    {completion.streak} day streak
                  </Badge>
                </div>
              )}
            </div>
            <LikesAndComments completionId={completion.id} />
          </Card>
        );
      })}
    </div>
  );
};

export default GroupFeed;
