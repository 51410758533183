// import { Button } from "@/components/ui/button";
import { api } from "../../convex/_generated/api";
import { useQuery } from "convex/react";
// import { Card } from "@/components/ui/card";
// import { ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
// import YouTube from "react-youtube";

const LearnPage = () => {
  const superhabits = useQuery(api.superhabits.get);
  const navigate = useNavigate();

  const handleNavigation = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  return (
    <div className="max-w-2xl mx-auto flex flex-col gap-2 pt-3">
      {/* <h2 className="font-semibold pb-1 px-2">Watch the Keynote</h2>
      <Card className="overflow-hidden">
        <div className="aspect-w-16 aspect-h-9">
          <YouTube
            videoId="leEQZqVbKtE"
            opts={{
              width: "100%",
              height: "100%",
            }}
            className="absolute inset-0"
          />
        </div>
      </Card> */}
      {/* <h2 className="font-semibold pb-1 px-2 pt-4">About the Superhabits</h2> */}
      {superhabits?.map((superhabit) => (
        <div
          key={superhabit._id}
          className="border-b border-gray-200 pt-1 pb-4 px-6 sm:px-4 cursor-pointer"
          onClick={() => handleNavigation(`/superhabit/${superhabit._id}`)}
        >
          <h2 className="text-base font-semibold mb-1">{superhabit.name}</h2>
          <p className="text-sm text-gray-500 line-clamp-2">{superhabit.definition}</p>
          {/* <div className="flex justify-end pt-3 -mr-2">
            <Button size="sm" variant="link" onClick={() => handleNavigation(`/superhabit/${superhabit._id}`)}>
              Learn More
              <ArrowRight className="w-4 h-4 ml-2" />
            </Button>
          </div> */}
        </div>
      ))}
      <div className="h-[10px] sm:h-[50px]"></div>
    </div>
  );
};

export default LearnPage;
