import React, { useState } from "react";
import { useSignIn, useSignUp } from "@clerk/clerk-react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { PhoneInput } from "@/components/ui/phone-input";
import logo from "@/assets/logo.svg";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "@/components/ui/input-otp";
import { Loader2 } from "lucide-react";
import { usePostHog } from "posthog-js/react";

const AuthForm = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState<"phone" | "otp">("phone");
  const { signIn, setActive } = useSignIn();
  const { signUp } = useSignUp();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const posthog = usePostHog();

  const handlePhoneSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    try {
      const signInAttempt = await signIn?.create({
        identifier: phoneNumber,
        strategy: "phone_code",
      });

      if (signInAttempt?.status === "needs_first_factor") {
        setStep("otp");
      }
    } catch (err) {
      console.log(err);
      // If sign-in fails, attempt to sign up
      try {
        const signUpAttempt = await signUp?.create({
          phoneNumber,
        });

        await signUpAttempt?.preparePhoneNumberVerification();
        setStep("otp");
      } catch (signUpErr) {
        console.error("Error during sign up:", signUpErr);
        if (signUpErr instanceof Error) {
          setError(`An error occurred: ${signUpErr.message}`);
        } else {
          setError("An unexpected error occurred. Please try again.");
        }
      }
    }
  };

  const handleOtpChange = (value: string) => {
    setOtp(value);
    if (value.length === 6) {
      handleOtpSubmit(value);
    }
  };

  const handleOtpSubmit = async (otp: string) => {
    setError("");
    setIsLoading(true);

    console.log("otp", otp);

    try {
      const signInAttempt = await signIn?.attemptFirstFactor({
        strategy: "phone_code",
        code: otp,
      });

      if (signInAttempt?.status === "complete") {
        await setActive?.({ session: signInAttempt.createdSessionId });
        posthog.capture("user signed in");
      }
    } catch (err) {
      console.log(err);
      // If sign-in fails, attempt to verify the sign-up
      try {
        const signUpAttempt = await signUp?.attemptPhoneNumberVerification({
          code: otp,
        });

        if (signUpAttempt?.status === "complete") {
          await setActive?.({ session: signUpAttempt.createdSessionId });
          posthog.capture("user signed up");
        }
      } catch (verifyErr) {
        console.error("Error during OTP verification:", verifyErr);
        setError("Invalid OTP. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="flex-grow max-w-[400px] py-2">
      <div id="clerk-captcha" />
      <CardHeader className="text-center">
        <div className="flex items-center justify-center pb-4">
          <img src={logo} alt="logo" className="w-8 h-8" />
        </div>
        <CardTitle className="text-xl font-bold">{step === "phone" ? "Sign in to Superhabits" : "Enter Code"}</CardTitle>
        <CardDescription className="text-xs text-gray-500">
          {step === "phone" ? "We'll send you a one-time code for verification" : "We've sent a one-time code to your phone"}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handlePhoneSubmit} className="space-y-3">
          {step === "phone" ? (
            <PhoneInput value={phoneNumber} onChange={setPhoneNumber} defaultCountry="US" placeholder="Your phone number" />
          ) : (
            <>
              <InputOTP maxLength={6} value={otp} onChange={handleOtpChange}>
                <InputOTPGroup className="mx-auto">
                  <InputOTPSlot index={0} />
                  <InputOTPSlot index={1} />
                  <InputOTPSlot index={2} />
                  <InputOTPSlot index={3} />
                  <InputOTPSlot index={4} />
                  <InputOTPSlot index={5} />
                </InputOTPGroup>
              </InputOTP>
              {isLoading && (
                <div className="flex justify-center">
                  <Loader2 className="h-6 w-6 animate-spin text-gray-500" />
                </div>
              )}
            </>
          )}
          <div className={`${step !== "phone" && "max-w-[240px] mx-auto"}`}>
            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
            {step === "phone" && (
              <Button type="submit" className="w-full rounded-lg">
                Send Code
              </Button>
            )}
          </div>
        </form>
      </CardContent>
      {step === "otp" && (
        <CardFooter>
          <Button variant="link" onClick={() => setStep("phone")} className="w-full">
            Change phone number
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};

export default AuthForm;
