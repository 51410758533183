import { api } from "../../convex/_generated/api";
import { useQuery } from "convex/react";
import { usePostHog } from "posthog-js/react";
import { useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import celebrationImage from "@/assets/celebration.png";
import { toast } from "@/hooks/use-toast";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Share2, ArrowRight } from "lucide-react";

const AssessmentResultsPage = () => {
  const navigate = useNavigate();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const posthog = usePostHog();

  const scoresChange = useQuery(api.assessments.getScoreChange);
  const profile = useQuery(api.profiles.get);

  const topImprovements = useMemo(() => {
    if (!scoresChange) return [];
    return scoresChange
      .filter((change) => Number(change.difference) > 0)
      .sort((a, b) => Number(b.difference) - Number(a.difference))
      .slice(0, 5);
  }, [scoresChange]);

  const handleShare = async () => {
    if (!topImprovements || topImprovements.length === 0) return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Increase canvas size and add more padding
    const padding = 60;
    canvas.width = 800;
    canvas.height = 650;

    // Try to load Arvo font
    let fontFamily = "Arvo, Arial, sans-serif";
    try {
      await document.fonts.load("16px Arvo");
      if (!document.fonts.check("16px Arvo")) {
        console.warn("Arvo font not available, falling back to system fonts");
        fontFamily = "Arial, sans-serif";
      }
    } catch (error) {
      console.warn("Error loading Arvo font, falling back to system fonts:", error);
      fontFamily = "Arial, sans-serif";
    }

    // Background
    ctx.fillStyle = "#f3f4f6";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Title
    ctx.fillStyle = "#111827";
    ctx.font = `bold 36px ${fontFamily}`;
    ctx.fillText(`${profile?.name ? profile.name + "'s" : "My"} Top Improvements`, padding, padding + 25);

    // Superhabits and improvements
    topImprovements.forEach((improvement, index) => {
      const y = padding + 100 + index * 80;

      // Superhabit name
      ctx.fillStyle = "#4b5563";
      ctx.font = `24px ${fontFamily}`;
      ctx.fillText(improvement.superhabitName, padding, y);

      // Improvement score
      ctx.fillStyle = "#5EC26A";
      ctx.font = `bold 24px ${fontFamily}`;
      ctx.fillText(`+${Number(improvement.difference).toFixed(1)}`, canvas.width - padding - 60, y);

      // Progress bar
      const barWidth = canvas.width - padding * 2;
      const barHeight = 3;
      ctx.fillStyle = "#e5e7eb";
      ctx.fillRect(padding, y + 15, barWidth, barHeight);
    });

    // App name and URL
    ctx.fillStyle = "#6b7280";
    ctx.font = `24px ${fontFamily}`;
    ctx.fillText("Find yours at superhabits.app", padding, canvas.height - padding);

    try {
      const imageBlob = await new Promise<Blob>((resolve) => canvas.toBlob((blob) => resolve(blob!)));
      const imageFile = new File([imageBlob], "superhabits-improvements.png", { type: "image/png" });

      const shareData = {
        text: "I improved my Superhabits! You can find yours at https://superhabits.link/scores",
        files: [imageFile],
      };

      if (navigator.canShare && navigator.canShare(shareData)) {
        try {
          await navigator.share(shareData);
          posthog.capture("user shared improvements");
        } catch (error) {
          if (error instanceof DOMException && error.name === "AbortError") {
            console.log("Share cancelled by user");
            // Do nothing, as this is not an error condition
          } else {
            throw error; // Re-throw other errors to be caught by the outer catch block
          }
        }
      } else {
        // Fallback for desktop or browsers that don't support Web Share API
        const imageUrl = URL.createObjectURL(imageBlob);
        const link = document.createElement("a");
        link.href = imageUrl;
        link.download = "superhabits-improvements.png";
        link.click();
        URL.revokeObjectURL(imageUrl);

        toast({
          title: "Image saved!",
          description: "Your Superhabits improvements image has been saved.",
        });
        posthog.capture("user shared improvements");
      }
    } catch (error) {
      console.error("Error sharing:", error);
      toast({
        title: "Sharing failed",
        description: "Unable to generate or share the image. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full overflow-y-auto">
      <div className="bg-stone-50 h-screen flex items-center justify-center">
        <div className="max-w-lg w-full mx-auto p-6 pt-0">
          {topImprovements.length > 0 && <img src={celebrationImage} alt="celebration" className="w-20 h-auto mx-auto" />}
          <Card className="w-full p-2">
            {topImprovements.length > 0 && (
              <CardHeader>
                <CardTitle className="text-xl font-semibold">Congrats on your improvements!</CardTitle>
              </CardHeader>
            )}
            <CardContent>
              <div className="flex flex-col gap-2">
                {topImprovements.length > 0 ? (
                  topImprovements.map((improvement, index) => (
                    <div
                      key={improvement.superhabitId}
                      className={`flex items-center justify-between ${
                        index < 4 ? "border-b-2 border-stone-100 pb-2 border-dashed" : ""
                      }`}
                    >
                      <div className="text-lg text-stone-700">{improvement.superhabitName}</div>
                      <div className="text-lg text-green-600">+{Number(improvement.difference).toFixed(1)}</div>
                    </div>
                  ))
                ) : (
                  <div className="pt-5">
                    <div className="text-center text-stone-700 font-semibold pb-2">No improvements yet</div>
                    <div className="text-center text-stone-600 text-sm">
                      Don't be discouraged. Habits can take time to form but with consistent work you'll see improvement soon!
                    </div>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
          <div className="px-4 pt-4">
            {topImprovements.length > 0 && (
              <Button onClick={handleShare} className="mt-4 rounded-xl w-full" disabled={topImprovements.length === 0}>
                <Share2 className="w-4 h-4 mr-2" /> Share improvements
              </Button>
            )}
            <Button onClick={() => navigate("/")} variant="outline" className="mt-3 rounded-xl w-full">
              Continue
              <ArrowRight className="w-4 h-4 ml-2" />
            </Button>
          </div>
        </div>
        <canvas ref={canvasRef} style={{ display: "none" }} />
      </div>
    </div>
  );
};

export default AssessmentResultsPage;
