import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  BookKey,
  BookOpenText,
  Component,
  Home,
  Menu,
  MessageCircleQuestion,
  MessagesSquare,
  Settings,
  Share2,
  X,
} from "lucide-react";
import { UserButton } from "@clerk/clerk-react";
import { Button } from "@/components/ui/button";
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from "@/components/ui/drawer";
import { useState, useCallback } from "react";
import { toast } from "@/hooks/use-toast";
import { usePostHog } from "posthog-js/react";

const Navigation = () => {
  const posthog = usePostHog();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const getIconColor = (path: string) => {
    return location.pathname === path ? "text-primary-500" : "text-stone-500 hover:text-primary-700";
  };

  const handleNavigation = useCallback(
    (path: string) => {
      setOpen(false);
      navigate(path);
    },
    [navigate]
  );

  const handleShare = () => {
    const shareData = {
      text: "The Superhabits app figures out *which* habits you need, and then helps you develop them. You should try it!",
      url: "https://superhabits.link/share",
    };

    if (navigator.share && navigator.canShare(shareData)) {
      navigator
        .share(shareData)
        .then(() => {
          posthog.capture("user shared app");
        })
        .catch((error) => console.log("Error sharing:", error));
    } else {
      // Fallback for desktop or browsers that don't support Web Share API
      navigator.clipboard
        .writeText(shareData.url)
        .then(() => {
          posthog.capture("user shared app");
          toast({
            title: "Link copied!",
            description: "The app link has been copied to your clipboard.",
          });
        })
        .catch((error) => {
          console.error("Failed to copy:", error);
          toast({
            title: "Sharing failed",
            description: "Unable to copy the link. Please try again.",
            variant: "destructive",
          });
        });
    }
  };

  return (
    <nav className="relative">
      <div className="fixed top-0 px-6 sm:px-7 lg:px-8 w-full bg-stone-50">
        <div className="flex justify-between h-[4rem] max-w-6xl mx-auto">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center space-x-1">
              <Drawer direction="left" open={open} onOpenChange={setOpen}>
                <Button onClick={() => setOpen(true)} variant="outline" size="icon" className="rounded-full w-9 h-9 p-2">
                  <Menu className="h-5 w-5 text-stone-500" />
                </Button>
                <DrawerContent className="h-full w-[300px] rounded-none border-none">
                  <DrawerHeader className="hidden">
                    <DrawerTitle>Menu</DrawerTitle>
                  </DrawerHeader>
                  <div className="flex justify-end pr-4">
                    <Button onClick={() => setOpen(false)} variant="outline" size="icon" className="rounded-full w-8 h-8 p-1">
                      <X className="h-6 w-6 text-stone-500" />
                    </Button>
                  </div>
                  <div className="flex flex-col">
                    <Button
                      variant="link"
                      className="text-lg flex justify-start pl-5"
                      size="lg"
                      onClick={() => handleNavigation("/introduction")}
                    >
                      <BookKey className="h-5 w-5 mr-3 text-primary-400" />
                      Introduction
                    </Button>
                    <Button
                      variant="link"
                      className="text-lg flex justify-start pl-5"
                      size="lg"
                      onClick={() => handleNavigation("/")}
                    >
                      <Home className="h-5 w-5 mr-3 text-primary-400" />
                      Home
                    </Button>
                    <Button
                      variant="link"
                      className="text-lg flex justify-start pl-5"
                      size="lg"
                      onClick={() => handleNavigation("/messages")}
                    >
                      <MessagesSquare className="h-5 w-5 mr-3 text-primary-400" />
                      AI Coach
                    </Button>
                    <Button
                      variant="link"
                      className="text-lg flex justify-start pl-5"
                      size="lg"
                      onClick={() => handleNavigation("/learn")}
                    >
                      <BookOpenText className="h-5 w-5 mt-[0.5px] mr-3 text-primary-400" />
                      Learn
                    </Button>
                    <Button
                      variant="link"
                      className="text-lg flex justify-start pl-5"
                      size="lg"
                      onClick={() => handleNavigation("/settings")}
                    >
                      <Settings className="h-5 w-5 mr-3 text-primary-400" />
                      Settings
                    </Button>
                    <Button
                      variant="link"
                      className="text-lg flex justify-start pl-5"
                      size="lg"
                      onClick={() => handleNavigation("/feedback")}
                    >
                      <MessageCircleQuestion className="h-5 w-5 mr-3 text-primary-400" />
                      Feedback
                    </Button>
                    <Button
                      variant="link"
                      className="text-lg flex justify-start pl-5"
                      size="lg"
                      onClick={() => handleNavigation("/groups")}
                    >
                      <Component className="h-5 w-5 mr-3 text-primary-400" />
                      My Groups
                    </Button>
                  </div>
                  <div className="flex flex-col mt-auto p-5 space-y-2">
                    <Button
                      onClick={handleShare}
                      variant="outline"
                      className="text-lg flex pl-5 text-primary-500 border-primary-300 rounded-xl w-full hover:text-primary-500 hover:border-primary-300"
                      size="lg"
                    >
                      <Share2 className="h-5 w-5 mr-3 text-primary-400" />
                      Share this app
                    </Button>
                    <div className="flex flex-col space-y-2 p-1 pt-3">
                      <Link
                        className="text-xs text-stone-400"
                        to="https://app.termly.io/policy-viewer/policy.html?policyUUID=8f743afb-1818-4065-87c5-cd872c41bcd9"
                      >
                        Privacy Policy
                      </Link>
                      <Link
                        className="text-xs text-stone-400"
                        to="https://app.termly.io/policy-viewer/policy.html?policyUUID=5b42651a-5ce4-47ad-a736-5a146c0d827d"
                      >
                        Terms of Service
                      </Link>
                    </div>
                  </div>
                </DrawerContent>
              </Drawer>
            </div>
          </div>
          <div className="flex items-center space-x-8">
            <Link to="/" className={getIconColor("/")}>
              <Home className="h-6 w-6 stroke-[1.5]" />
            </Link>
            <Link to="/messages" className={getIconColor("/messages")}>
              <MessagesSquare className="h-6 w-6 stroke-[1.5]" />
            </Link>
            <UserButton />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
