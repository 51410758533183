import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Button } from "@/components/ui/button";
import { ArrowLeft, Loader2 } from "lucide-react";

const SuperhabitPage = () => {
  const { id } = useParams<{ id: string }>();
  const superhabit = useQuery(api.superhabits.getById, { id: id as string });
  const navigate = useNavigate();

  if (!superhabit) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="w-4 h-4 animate-spin" />
      </div>
    );
  }

  return (
    <div className="p-8 pt-4 max-w-2xl mx-auto flex flex-col gap-3">
      <div>
        <Button variant="link" onClick={() => navigate(-1)} size="sm" className="-ml-2">
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back
        </Button>
      </div>
      <h1 className="text-2xl font-bold text-stone-900">{superhabit.name}</h1>
      <p className="text-stone-600">{superhabit.definition}</p>
      <p className="text-stone-600">{superhabit.description}</p>
      <h2 className="text-lg text-stone-900 font-semibold">Benefits</h2>
      <p className="text-stone-600">{superhabit.benefits}</p>
      <h2 className="text-lg text-stone-900 font-semibold">How to practice</h2>
      <p className="text-stone-600">{superhabit.practices}</p>
      <p className="text-stone-600">For example:</p>
      <p className="text-stone-600">{superhabit.examplePractices[0]}</p>
    </div>
  );
};

export default SuperhabitPage;
