import { useUser } from "@clerk/clerk-react";
import { Authenticated, Unauthenticated, useQuery } from "convex/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./components/Navigation";
import Dashboard from "./pages/Dashboard";
import MessagePage from "./pages/MessagePage";
// import OptInPage from "./pages/OptInPage";
import { api } from "../convex/_generated/api";
import OnboardingFormPage from "./pages/OnboardingFormPage";
import InitialAssessmentPage from "./pages/InitialAssessmentPage";
import InitialMessagePage from "./pages/InitialMessagePage";
import { useEffect } from "react";
import posthog from "posthog-js";
import AuthForm from "./components/AuthForm";
import IntroductionPage from "./pages/IntroductionPage";
import HashLoader from "react-spinners/HashLoader";
import FeedbackPage from "./pages/FeedbackPage";
import LearnPage from "./pages/LearnPage";
import SuperhabitPage from "./pages/SuperhabitPage";
import SettingsPage from "./pages/SettingsPage";
import GroupPage from "./pages/GroupPage";
import AssessmentPage from "./pages/AssessmentPage";
import AssessmentResultsPage from "./pages/AssessmentResultsPage";
import NotFoundPage from "./pages/NotFound";
import GroupDetailsPage from "./pages/GroupDetailsPage";
import ActivityPage from "./pages/ActivityPage";

const AuthenticatedApp = () => {
  const { user } = useUser();
  const profile = useQuery(api.profiles.get);
  const isAssessmentComplete = useQuery(api.assessments.isAssessmentComplete);
  const isOnboardingComplete = profile?.isOnboardingComplete;

  const isLoading = profile === undefined || isAssessmentComplete === undefined;

  useEffect(() => {
    if (user) {
      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(user.id);
    }
  }, [user]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <HashLoader color={"#925743"} loading={true} size={6} aria-label="Loading..." data-testid="loader" />
      </div>
    );
  }

  if (!profile) {
    return <OnboardingFormPage />;
  }

  if (!isAssessmentComplete) {
    return <InitialAssessmentPage />;
  }

  if (!isOnboardingComplete) {
    return <InitialMessagePage />;
  }

  return (
    <div className="flex flex-col h-[100dvh] bg-stone-50 overflow-hidden">
      <Navigation />
      <main className="flex-grow overflow-hidden pt-[4rem]">
        <div className="h-full overflow-auto">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/messages" element={<MessagePage />} />
            <Route path="/introduction" element={<IntroductionPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/feedback" element={<FeedbackPage />} />
            <Route path="/learn" element={<LearnPage />} />
            <Route path="/superhabit/:id" element={<SuperhabitPage />} />
            <Route path="/groups" element={<GroupPage />} />
            <Route path="/assessment" element={<AssessmentPage />} />
            <Route path="/assessment/results" element={<AssessmentResultsPage />} />
            <Route path="/groups/:id" element={<GroupDetailsPage />} />
            <Route path="/post/:id" element={<ActivityPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </main>
    </div>
  );
};

function App() {
  return (
    <Router>
      <Unauthenticated>
        <div className="flex items-center justify-center min-h-screen bg-stone-50 p-4">
          <AuthForm />
        </div>
      </Unauthenticated>
      <Authenticated>
        <AuthenticatedApp />
      </Authenticated>
    </Router>
  );
}

export default App;
