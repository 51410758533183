import { useEffect, useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { SendHorizontal } from "lucide-react";
import { useMutation, useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import BeatLoader from "react-spinners/BeatLoader";

const Chat = () => {
  const messages = useQuery(api.messages.list);
  const sendMessage = useMutation(api.messages.send);
  const [input, setInput] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);

  const handleSend = async () => {
    if (input.trim()) {
      setInput("");
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.blur();
      }
    }
    await sendMessage({ body: input, author: "user", channel: "web" });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent newline
      if (input.trim()) {
        // Only send if input is not empty
        handleSend();
      }
    }
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ block: "start", behavior: "instant" });
    }
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    // Prevent zoom on input focus for mobile devices
    const metaViewport = document.querySelector("meta[name=viewport]");
    const viewportContent = metaViewport?.getAttribute("content") || "";
    metaViewport?.setAttribute("content", `${viewportContent}, maximum-scale=1, user-scalable=0`);

    return () => {
      metaViewport?.setAttribute("content", viewportContent);
    };
  }, []);

  const renderMessage = (message: { author: string; body: string }) => {
    if (message.author === "function") {
      const functionData = JSON.parse(message.body);
      return <div className="text-sm text-stone-500 italic mt-4 mb-3 px-2 sm:text-right">{functionData.response}</div>;
    }

    if (!message.body) {
      return null;
    }

    return (
      <div className={`flex mb-2 ${message.author === "user" ? "justify-end" : "justify-start"}`}>
        <div
          className={`max-w-[90%] pt-[0.8rem] pb-3 px-[0.9rem] rounded-2xl font-light leading-relaxed text-sm whitespace-pre-wrap ${
            message.author === "user"
              ? "bg-primary-300 text-primary-950 shadow-md shadow-primary/15 border-2 border-primary/5"
              : "bg-white text-stone-700 border-2 border-stone-100 shadow-2xl shadow-primary/5"
          }`}
        >
          {message.body === "..." ? (
            <BeatLoader color={"#925743"} loading={true} size={6} aria-label="Loading..." data-testid="loader" />
          ) : (
            message.body
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex flex-col h-[calc(100vh-4rem)] overflow-y-auto max-w-3xl mx-auto px-3 sm:px-6">
        <div className="pt-2 pb-[200px] ">
          {messages?.map((message, i) => (
            <div key={i} ref={i === messages.length - 1 ? lastMessageRef : null}>
              {renderMessage(message)}
            </div>
          ))}
          {!!messages?.length && (
            <div className="text-xs text-stone-400 pt-3 px-2 sm:text-right">
              This is an AI powered assistant and can make mistakes. Please seek a professional for sensitive issues.
            </div>
          )}
        </div>
      </div>
      <div className="fixed -bottom-2 sm:bottom-3 w-full">
        <div className="w-[95%] max-w-3xl mx-auto relative">
          <textarea
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
              e.target.style.height = "auto";
              e.target.style.height = e.target.scrollHeight + "px";
            }}
            placeholder="Type your message..."
            onKeyDown={handleKeyDown}
            className="shadow-md sm:shadow-sm m-0 text-sm border w-full min-h-[80px] max-h-[190px] p-3 pl-4 pr-16 rounded-t-2xl sm:rounded-2xl resize-none overflow-hidden focus:outline-none focus:ring-2 focus:ring-primary-300"
          />
          {input.trim() && (
            <Button size="icon" onClick={handleSend} className="absolute top-3 right-3 rounded-xl">
              <SendHorizontal size="18" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;
