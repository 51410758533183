import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getScoreColor = (score: number) => {
  if (score < 3) return "text-red-500";
  if (score >= 3 && score < 6) return "text-yellow-500";
  if (score >= 6) return "text-green-500";
  return "text-stone-500";
};

export const getStreakColor = (streak: number) => {
  if (streak === 0) return "stone";
  if (streak < 3) return "blue";
  if (streak < 5) return "cyan";
  if (streak < 7) return "emerald";
  if (streak < 14) return "lime";
  if (streak < 21) return "amber";
  return "red";
};
