import { Card } from "@/components/ui/card";
import { Id } from "../../convex/_generated/dataModel";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { getStreakColor } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { ArrowRight, CheckCheck, Flame, UserPlus } from "lucide-react";
import { Button } from "./ui/button";
import { useNavigate } from "react-router-dom";
import { toast } from "@/hooks/use-toast";

const GroupCard = ({ groupId }: { groupId: Id<"groups"> }) => {
  const navigate = useNavigate();
  const groups = useQuery(api.groups.getGroups, { groupType: "personal" });
  const groupMembersAndStats = useQuery(api.groups.getGroupMembersAndStats, { groupId });
  const groupStreak = useQuery(api.groups.getGroupStreak, { groupId });
  const group = groups?.find((group) => group?._id === groupId);

  const groupCompletions = groupMembersAndStats?.reduce((acc, member) => acc + member.completions, 0);
  const streakColor = getStreakColor(groupStreak || 0);

  const handleShareGroup = async () => {
    const shareData = {
      text: `Building habits is easier with friends! You can join my Superhabits group "${group?.name}" using the code ${group?.joinCode} or by clicking this link: https://superhabits.link/group?code=${group?.joinCode}`,
    };

    if (navigator.canShare && navigator.canShare(shareData)) {
      await navigator.share(shareData);
    } else {
      navigator.clipboard.writeText(`${group?.joinCode}`);
      toast({
        title: "Group code copied to clipboard",
        description: "You can share it with others via email or other apps.",
        variant: "default",
      });
    }
  };

  return (
    <Card className="py-4 px-5 space-y-1">
      <p className="text-md text-stone-800 font-semibold">{group?.name}</p>
      <div className="flex items-center justify-between">
        <div className="flex space-x-2 pt-2 -mx-1">
          <Badge className={`text-xs text-primary-600 whitespace-nowrap py-1.5 leading-none bg-primary-100`}>
            <CheckCheck size="14" className="mr-1 mb-[1px]" />
            Completions: {groupCompletions}
          </Badge>
          <Badge
            className={`text-xs text-white whitespace-nowrap py-1.5 leading-none
              ${
                streakColor === "stone"
                  ? "bg-stone-400"
                  : streakColor === "blue"
                    ? "bg-blue-400"
                    : streakColor === "cyan"
                      ? "bg-cyan-400"
                      : streakColor === "emerald"
                        ? "bg-emerald-400"
                        : streakColor === "lime"
                          ? "bg-lime-400"
                          : streakColor === "amber"
                            ? "bg-amber-400"
                            : "bg-red-400"
              }`}
          >
            <Flame size="14" className="mr-1 mb-[1px]" />
            Streak: {groupStreak}
          </Badge>
        </div>
      </div>
      <div className="flex justify-between pt-3 -mx-1">
        <Button
          onClick={() => navigate(`/groups/${groupId}`)}
          variant="outline"
          size="sm"
          className="rounded-full px-3 text-stone-700"
        >
          Group Details
          <ArrowRight size="14" className="text-stone-500 ml-1" />
        </Button>
        <Button onClick={handleShareGroup} variant="outline" size="sm" className="rounded-full px-3 text-stone-700">
          <UserPlus size="14" className="text-stone-500 mr-1" />
          Invite
        </Button>
      </div>
    </Card>
  );
};

export default GroupCard;
