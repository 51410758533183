import { toZonedTime, fromZonedTime } from "date-fns-tz";
import { Doc } from "./_generated/dataModel";

export function calculateStreak(completions: Doc<"completions">[], timezone: string): number {
  if (!completions.length) return 0;

  // Convert all dates to the user's timezone, then normalize to start of day
  const normalizedCompletions = completions
    .map((c) => {
      const zonedDate = toZonedTime(new Date(c.date), timezone);
      return {
        ...c,
        date: fromZonedTime(new Date(zonedDate.getFullYear(), zonedDate.getMonth(), zonedDate.getDate()), timezone),
      };
    })
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  let streak = 0;
  const today = toZonedTime(new Date(), timezone);
  today.setHours(0, 0, 0, 0);

  const lastCompletionDate = normalizedCompletions[normalizedCompletions.length - 1].date;

  // Check if the last completion was yesterday or today
  const daysSinceLastCompletion = Math.floor((today.getTime() - lastCompletionDate.getTime()) / (1000 * 60 * 60 * 24));

  if (daysSinceLastCompletion <= 1) {
    streak = 1;

    for (let i = normalizedCompletions.length - 1; i > 0; i--) {
      const current = normalizedCompletions[i].date;
      const previous = normalizedCompletions[i - 1].date;

      const diffDays = Math.floor((current.getTime() - previous.getTime()) / (1000 * 60 * 60 * 24));

      if (diffDays === 1) {
        streak += 1;
      } else if (diffDays === 0) {
        continue;
      } else {
        break;
      }
    }
  }

  return streak;
}
