import { Card } from "@/components/ui/card";
import { api } from "../../convex/_generated/api";
import { useMutation, useQuery } from "convex/react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { toast } from "@/hooks/use-toast";
import { ArrowRight } from "lucide-react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { CreateGroupCard } from "@/components/CreateGroupCard";

const GroupPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const groups = useQuery(api.groups.getGroups, {});
  const joinGroup = useMutation(api.groups.join);

  const [groupCode, setGroupCode] = useState("");
  const [groupCodeParam, setGroupCodeParam] = useState("");
  const [joinDialogOpen, setJoinDialogOpen] = useState(false);

  const groupFromJoinCode = useQuery(api.groups.getByCode, {
    code: groupCodeParam,
  });

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      setGroupCodeParam(code);
    }
  }, [searchParams]);

  useEffect(() => {
    if (groupFromJoinCode) {
      setJoinDialogOpen(true);
    }
  }, [groupFromJoinCode]);

  const handleJoinGroup = async () => {
    try {
      await joinGroup({ code: groupCode });
      setGroupCode("");
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Invalid group code",
        variant: "destructive",
      });
    }
  };

  const handleJoinInvitedGroup = async () => {
    await joinGroup({ code: groupCodeParam });
    setJoinDialogOpen(false);
    navigate(`/groups/${groupFromJoinCode?._id}`);
  };

  return (
    <div className="p-4 pt-5 sm:pt-8 max-w-xl mx-auto">
      <CreateGroupCard className="mb-5" />
      <Card className="p-3 mb-2 rounded-3xl flex items-center justify-between">
        <Input
          maxLength={6}
          placeholder="Group Code"
          className="rounded-l-xl rounded-r-none"
          value={groupCode}
          onChange={(e) => setGroupCode(e.target.value)}
        />
        <Button disabled={groupCode.length !== 6} className="rounded-r-xl rounded-l-none" onClick={handleJoinGroup}>
          Join Group
        </Button>
      </Card>
      <div className="">
        <h1 className="text-xl font-bold p-3 pb-1">My Groups</h1>
        {groups?.map((group) => (
          <Link to={`/groups/${group?._id}`} key={group?._id}>
            <Card className="p-5 rounded-2xl mt-2 flex items-center justify-between">
              <p className="font-semibold text-primary-600">{group?.name}</p>
              <ArrowRight className="w-4 h-4 text-primary-600" />
            </Card>
          </Link>
        ))}
        {groups?.length === 0 && (
          <div className="text-sm text-stone-500 mt-2 leading-relaxed px-3">You haven't joined any groups yet.</div>
        )}
      </div>

      <Dialog open={joinDialogOpen} onOpenChange={setJoinDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Join Group</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-2">
            <p className="text-base text-stone-600">You've been invited to join {groupFromJoinCode?.name}!</p>
            <p className="text-base text-stone-600">Joining a group will share your habit activity and top 5 Superhabits.</p>
            <Button className="mt-3" onClick={handleJoinInvitedGroup}>
              Join Group
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default GroupPage;
