import React, { useState, useEffect, PropsWithChildren } from "react";
import { Button } from "./ui/button";
import { SquarePlus, X } from "lucide-react";
import { IoShareOutline } from "react-icons/io5";

declare global {
  interface Navigator {
    standalone?: boolean;
  }
}

const isIOSSafari = () => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
};

const isInstalledPWA = () => {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone ||
    document.referrer.includes("android-app://")
  );
};

const WithInstallPrompt: React.FC<PropsWithChildren> = ({ children }) => {
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const checkPromptConditions = () => {
      const lastPromptDate = localStorage.getItem("lastInstallPromptDate");
      const currentDate = new Date().getTime();
      const oneMonth = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

      if (isIOSSafari() && !isInstalledPWA() && (!lastPromptDate || currentDate - parseInt(lastPromptDate) > oneMonth)) {
        setShowPrompt(true);
      }
    };

    checkPromptConditions();
  }, []);

  const handleClosePrompt = () => {
    setShowPrompt(false);
    localStorage.setItem("lastInstallPromptDate", new Date().getTime().toString());
  };

  return (
    <>
      {showPrompt && (
        <div className="fixed bottom-0 left-0 right-0 z-50 bg-cyan-700 p-4">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold text-white pl-2">Install Superhabits</h2>
            <Button onClick={handleClosePrompt} size="icon" variant="ghost">
              <X size={24} className="text-white" />
            </Button>
          </div>
          <div className="flex flex-col p-1 pt-4 space-y-5">
            <div className="flex items-center space-x-3">
              <IoShareOutline size={32} className="text-white" />
              <p className="text-white text-sm">1. Press the "Share" icon on the menu bar below</p>
            </div>
            <div className="flex items-center space-x-3">
              <SquarePlus size={28} className="text-white mx-[1.5px]" strokeWidth={1.7} />
              <p className="text-white text-sm">2. Press "Add to Home Screen"</p>
            </div>
          </div>
        </div>
      )}
      {children}
    </>
  );
};

export default WithInstallPrompt;
