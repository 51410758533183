import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useMutation, useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Loader2, X } from "lucide-react";

const commonTimezones = [
  "Pacific/Midway",
  "Pacific/Honolulu",
  "America/Anchorage",
  "America/Los_Angeles",
  "America/Vancouver",
  "America/Phoenix",
  "America/Denver",
  "America/Chicago",
  "America/Mexico_City",
  "America/New_York",
  "America/Toronto",
  "America/Bogota",
  "America/Caracas",
  "America/Santiago",
  "America/St_Johns",
  "America/Sao_Paulo",
  "America/Argentina/Buenos_Aires",
  "Atlantic/Azores",
  "Europe/London",
  "Europe/Paris",
  "Europe/Berlin",
  "Europe/Rome",
  "Europe/Moscow",
  "Africa/Cairo",
  "Africa/Johannesburg",
  "Asia/Jerusalem",
  "Asia/Dubai",
  "Asia/Karachi",
  "Asia/Kolkata",
  "Asia/Bangkok",
  "Asia/Singapore",
  "Asia/Tokyo",
  "Australia/Perth",
  "Australia/Sydney",
  "Pacific/Auckland",
];

const OnboardingFormPage = () => {
  const createProfile = useMutation(api.profiles.create);
  const joinGroup = useMutation(api.groups.join);

  const [name, setName] = useState("");
  const [groupCode, setGroupCode] = useState("");
  const [nameError, setNameError] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [timeZone, setTimeZone] = useState("");

  const groupResult = useQuery(api.groups.getByCode, { code: groupCode });

  const timezoneOptions = useMemo(() => {
    const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (detectedTimeZone && !commonTimezones.includes(detectedTimeZone)) {
      return [...commonTimezones, detectedTimeZone].sort();
    }
    return commonTimezones;
  }, []);

  useEffect(() => {
    // Automatically detect the user's timezone
    const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(detectedTimeZone);
  }, []);

  const validateName = (value: string) => {
    if (value.length < 2) {
      setNameError("Name must be at least 2 characters.");
    } else {
      setNameError("");
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
    validateName(value);
  };

  const handleGroupCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase(); // Ensure uppercase input
    setGroupCode(value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (name.length >= 2) {
      // Handle form submission here
      if (groupCode.length === 6 && groupResult) {
        joinGroup({ code: groupCode });
      }
      createProfile({ name, subscribed: isSubscribed, timeZone });
    } else {
      validateName(name);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4 py-8">
      <Card className="w-full max-w-md space-y-8 p-10">
        <h1 className="text-2xl font-bold text-center mb-6">Create Your Profile</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-stone-700 mb-1 ml-1">
              Your First Name
            </label>
            <Input id="name" placeholder="Enter your name" value={name} onChange={handleNameChange} className="w-full" />
            {nameError && <p className="text-red-500 text-sm mt-1">{nameError}</p>}
          </div>

          <div>
            <label htmlFor="timeZone" className="block text-sm font-medium text-stone-700 mb-1 ml-1">
              Time Zone
            </label>
            <Select onValueChange={setTimeZone} value={timeZone}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select your time zone" />
              </SelectTrigger>
              <SelectContent>
                {timezoneOptions.map((tz) => (
                  <SelectItem key={tz} value={tz}>
                    {tz}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          {groupResult ? (
            <div>
              <label htmlFor="groupCode" className="block text-sm font-medium text-stone-700 mb-1 ml-1">
                Your Group
              </label>
              <div className="border-2 border-primary-400 rounded-md pl-4 p-2 flex items-center justify-between">
                <p className="text-primary-700 text-base">{groupResult.name}</p>
                <Button size="icon" variant="ghost" onClick={() => setGroupCode("")}>
                  <X className="w-5 h-5 text-primary-700" />
                </Button>
              </div>
            </div>
          ) : groupResult === undefined && groupCode.length === 6 ? (
            <div>
              <label htmlFor="groupCode" className="block text-sm font-medium text-stone-700 mb-1 ml-1">
                Your Group
              </label>
              <div className="border border-stone-200 rounded-md p-1 flex items-center justify-around">
                <Loader2 className="animate-spin" />
              </div>
            </div>
          ) : (
            <div>
              <label htmlFor="groupCode" className="block text-sm font-medium text-stone-700 mb-1 ml-1">
                Group Code (Optional)
              </label>
              <Input
                id="groupCode"
                placeholder="Enter 6-character group code"
                value={groupCode}
                onChange={handleGroupCodeChange}
                className="w-full"
                maxLength={6}
              />
              {groupCode.length >= 6 && !groupResult && <p className="text-red-500 text-sm mt-2">Invalid group code</p>}
            </div>
          )}

          <div>
            <div className="flex items-center space-x-2">
              <Checkbox id="terms" checked={isSubscribed} onCheckedChange={(checked) => setIsSubscribed(checked as boolean)} />
              <Label htmlFor="terms" className="text-sm">
                I agree to receive SMS updates
              </Label>
            </div>
            <p className="text-xs text-stone-500 mt-2">
              I understand that message and data rates may apply. I will receive up to 2 messages per day with reminders to complete
              my daily habits. I can text STOP to unsubscribe at any time.
            </p>
          </div>

          <Button type="submit" className="w-full rounded-xl">
            Continue
          </Button>
          <p className="text-sm text-stone-500">
            By continuing, you agree to our{" "}
            <a
              href="https://app.termly.io/policy-viewer/policy.html?policyUUID=5b42651a-5ce4-47ad-a736-5a146c0d827d"
              className="text-primary-500"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://app.termly.io/policy-viewer/policy.html?policyUUID=8f743afb-1818-4065-87c5-cd872c41bcd9"
              className="text-primary-500"
            >
              Privacy Policy
            </a>
            .
          </p>
        </form>
      </Card>
    </div>
  );
};

export default OnboardingFormPage;
