import { api } from "../../convex/_generated/api";
import { useMutation, useQuery } from "convex/react";
import { Button } from "@/components/ui/button";
import { useState, useEffect, useMemo } from "react";
import { ArrowLeft } from "lucide-react";
import { Id } from "../../convex/_generated/dataModel";
import { Progress } from "@/components/ui/progress";
import { useNavigate } from "react-router-dom";

const responseValues = [
  "7 - Agree strongly",
  "6 - Agree moderately",
  "5 - Agree a little",
  "4 - Neither agree nor disagree",
  "3 - Disagree a little",
  "2 - Disagree moderately",
  "1 - Disagree strongly",
];

interface Question {
  superhabitId: Id<"superhabits">;
  superhabitName: string;
  question: string;
  scoreRange: number[];
}

const calculateScore = (answers: Record<string, number>, questions: Question[]) => {
  const superhabitScores: Record<string, number[]> = {};

  // Group scores by superhabit
  Object.entries(answers).forEach(([key, value]) => {
    const [superhabitId] = key.split("-");
    if (!superhabitScores[superhabitId]) {
      superhabitScores[superhabitId] = [];
    }
    superhabitScores[superhabitId].push(value);
  });

  // Calculate average for each superhabit
  return Object.entries(superhabitScores).map(([superhabitId, scores]) => {
    const average = scores.reduce((sum, score) => sum + score, 0) / scores.length;
    const superhabit = questions.find((q: Question) => q.superhabitId === superhabitId);
    return {
      superhabitId: superhabitId as Id<"superhabits">,
      superhabitName: superhabit?.superhabitName ?? "",
      score: Number(average.toFixed(2)), // Round to 2 decimal places
    };
  });
};

const AssessmentPage = () => {
  const navigate = useNavigate();
  const superhabitQuestions = useQuery(api.assessments.getFollowUpQuestions);
  const submitAssessment = useMutation(api.assessments.submitAssessment);

  const questions = useMemo(() => superhabitQuestions ?? [], [superhabitQuestions]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<Record<string, number>>({});
  const [fadeState, setFadeState] = useState("in");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Load saved progress from localStorage
    try {
      const savedProgress = localStorage.getItem("assessmentProgress");
      if (savedProgress) {
        const { currentQuestionIndex: savedQuestionIndex, answers: savedAnswers } = JSON.parse(savedProgress);
        setCurrentQuestionIndex(savedQuestionIndex);
        setAnswers(savedAnswers);
      }
    } catch (e) {
      console.error("Failed to load progress from localStorage:", e);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    // Save progress to localStorage whenever it changes
    if (!isLoading) {
      localStorage.setItem(
        "assessmentProgress",
        JSON.stringify({
          currentQuestionIndex,
          answers,
        })
      );
    }
  }, [currentQuestionIndex, answers, isLoading]);

  useEffect(() => {
    if (fadeState === "out") {
      const timer = setTimeout(() => {
        setFadeState("in");
      }, 300); // Match this with the transition duration
      return () => clearTimeout(timer);
    }
  }, [fadeState]);

  const handleAnswer = (superhabitId: string, value: number) => {
    const answerKey = `${superhabitId}-${currentQuestionIndex}`;
    const isLastQuestion = currentQuestionIndex === questions.length - 1;

    // Only trigger fade out if it's not the last question
    if (!isLastQuestion) {
      setFadeState("out");
    }

    setAnswers((prev) => ({ ...prev, [answerKey]: value }));

    // Only move to the next question if it's not the last one
    if (!isLastQuestion) {
      setTimeout(() => {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }, 300); // Match this with the transition duration
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setFadeState("out");
      setTimeout(() => {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
      }, 300); // Match this with the transition duration
    }
  };

  const handleSubmit = async () => {
    const scores = calculateScore(answers, questions);
    await submitAssessment({ scores });

    localStorage.removeItem("assessmentProgress");

    navigate("/assessment/results");
  };

  if (isLoading) {
    return;
  }

  if (questions.length === 0) {
    return;
  }

  const currentQuestion = questions[currentQuestionIndex];
  const answerKey = currentQuestion ? `${currentQuestion.superhabitId}-${currentQuestionIndex}` : "";

  return (
    <div className="fixed top-0 left-0 w-full h-full overflow-auto">
      <div className="flex items-center justify-center min-h-screen bg-gray-100 px-6 py-8">
        <div className="w-full max-w-md">
          {currentQuestion ? (
            <div
              className={`text-stone-700 leading-relaxed transition-opacity duration-300 ease-in-out ${
                fadeState === "in" ? "opacity-100" : "opacity-0"
              }`}
            >
              <p className="text-lg font-semibold mb-6 px-2">{currentQuestion.question}</p>
              <div className="space-y-3">
                {currentQuestion.scoreRange.map((value, index) => (
                  <Button
                    key={index}
                    className="w-full rounded-xl justify-start p-6"
                    variant={answers[answerKey] === value ? "default" : "outline"}
                    size="lg"
                    onClick={() => handleAnswer(currentQuestion.superhabitId, value)}
                  >
                    <span className="text-left text-lg">{responseValues[index]}</span>
                  </Button>
                ))}
              </div>
            </div>
          ) : (
            <div>No question available</div>
          )}
          <div className="flex justify-between space-x-3 mt-auto pt-6">
            <Button variant="ghost" className="w-full rounded-xl" onClick={handlePrevious}>
              <ArrowLeft className="w-4 h-4 mr-2" />
              Back
            </Button>
            {currentQuestionIndex === questions.length - 1 && (
              <Button className="w-full rounded-xl" onClick={handleSubmit} disabled={!answers[answerKey]}>
                Submit Answers
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 w-full">
        <Progress className="rounded-none h-2" value={(currentQuestionIndex / questions.length) * 100} />
      </div>
    </div>
  );
};

export default AssessmentPage;
