import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { ClerkProvider, useAuth } from "@clerk/clerk-react";
import { ConvexProviderWithClerk } from "convex/react-clerk";
import { ConvexReactClient } from "convex/react";
import { Toaster } from "@/components/ui/toaster";
import * as Sentry from "@sentry/react";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import { registerSW } from "virtual:pwa-register";
import WithInstallPrompt from "@/components/WithInstallPrompt";

registerSW({ immediate: true });

if (import.meta.env.PROD) {
  posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
    api_host: import.meta.env.VITE_POSTHOG_HOST,
    person_profiles: "identified_only",
  });
}

const convex = new ConvexReactClient(import.meta.env.VITE_CONVEX_URL as string);

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    posthog.sentryIntegration({
      organization: "superhabits",
      projectId: 4507987318341632,
      severityAllowList: ["error", "info"], // optional: here is set to handle captureMessage (info) and captureException (error)
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["my.superhabits.app"],
  // Session Replay
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <WithInstallPrompt>
      <PostHogProvider client={posthog}>
        <ClerkProvider publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}>
          <ConvexProviderWithClerk client={convex} useAuth={useAuth}>
            <App />
            <Toaster />
          </ConvexProviderWithClerk>
        </ClerkProvider>
      </PostHogProvider>
    </WithInstallPrompt>
  </React.StrictMode>
);
